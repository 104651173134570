import { Box, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { typography } from 'theme';

const Text = ({ variant = 'h1', children, ...props }) => (
  <Typography variant={variant} component="div" color="primary" {...props}>
    {children}
  </Typography>
);

/**
 * Display font, a wrapper of Mui Box
 * @param props Mui Box props
 */
export const Display = (props) => (
  <Box
    component="div"
    {...typography[useIsMobileView() ? 'title1' : 'display']}
    {...props}
  />
);

/**
 * Title1 font, a wrapper of Mui Box
 * @param props Mui Box props
 */
export const Title1 = (props) => (
  <Box
    component="h1"
    {...typography[useIsMobileView() ? 'title2' : 'title1']}
    {...props}
  />
);

/**
 * Title2 font, a wrapper of Mui Box
 * @param props Mui Box props
 */
export const Title2 = (props) => (
  <Box
    component="h2"
    {...typography[useIsMobileView() ? 'title3' : 'title2']}
    {...props}
  />
);

/**
 * Title3 font, a wrapper of Mui Box
 * @param props Mui Box props
 */
export const Title3 = (props) => (
  <Box
    component="h3"
    {...typography[useIsMobileView() ? 'large' : 'title3']}
    {...props}
  />
);

/**
 * Large font, a wrapper of Mui Box
 * @param props Mui Box props
 * @param props.bold
 */
export const Large = ({ bold, ...props }) => (
  <Box
    component="p"
    {...typography.large}
    fontWeight={bold ? 500 : 'fontWeightRegular'}
    {...props}
  />
);

/**
 * Body font, a wrapper of Mui Box
 * @param props Mui Box props
 * @param props.bold
 */
export const Body = ({ bold, ...props }) => (
  <Box
    component="p"
    {...typography.body}
    fontWeight={bold ? 500 : 'fontWeightRegular'}
    {...props}
  />
);

/**
 * Small font, a wrapper of Mui Box
 * @param props Mui Box props
 * @param props.bold
 */
export const Small = ({ bold, ...props }) => (
  <Box
    component="p"
    {...typography.small}
    fontWeight={bold ? 500 : 'fontWeightRegular'}
    {...props}
  />
);

export default Text;
