import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

const RouterLinkBehavior = React.forwardRef(({ href, ...props }, ref) => (
  <RouterLink ref={ref} to={href} {...props} />
));

const Link = ({
  children,
  underline = 'none',
  target,
  color = 'inherit',
  linkProps,
  href,
  ...props
}) => (
  <MuiLink
    component={RouterLinkBehavior}
    href={href}
    underline={underline}
    target={target}
    color={color}
    {...props}
  >
    {children}
  </MuiLink>
);

export default Link;
