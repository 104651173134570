import { Box, Button, IconButton } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import Text from 'components/Text';
import Divider from 'components/Divider';

const PageHeader = ({
  title,
  actionLabel,
  actionLink,
  titleComponent,
  actionComponent,
  hideAction,
  children,
}) => {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" sx={{ mb: 2 }}>
          <IconButton variant="contained" onClick={handleBack}>
            <ArrowCircleLeftOutlinedIcon color="primary" />
          </IconButton>
          {titleComponent || <Text sx={{ marginLeft: 1 }}>{title}</Text>}
        </Box>
        {!hideAction && (
          <>
            {actionComponent || (
              <Button component={RouterLink} variant="text" to={actionLink}>
                {actionLabel}
              </Button>
            )}
          </>
        )}
      </Box>
      {children && <Box mb={2}>{children}</Box>}
      <Box mb={3}>
        <Divider />
      </Box>
    </>
  );
};

export default PageHeader;
