import { Avatar as MuiAvatar, Box, styled } from '@mui/material';
import HiddenInput from 'components/HiddenInput';
import DefaultProfilePicture from 'images/default-profile.png';

const Avatar = styled(MuiAvatar)(({ diameter }) => ({
  width: diameter,
  height: diameter,
}));

const getAvatarUrl = (avatar) => {
  if (!avatar) return DefaultProfilePicture;
  if (typeof avatar === 'string') return avatar;
  return URL.createObjectURL(avatar);
};

const Clickable = styled(Box)(() => ({
  '& .MuiAvatar-root': {
    cursor: 'pointer',
  },
}));

const ProfilePicture = ({ url, onChange, boxProps, ...props }) => (
  <Clickable {...boxProps}>
    <label htmlFor="profile-picture">
      <Avatar diameter={100} src={getAvatarUrl(url)} {...props} />
      <HiddenInput
        id="profile-picture"
        accept="image/*"
        type="file"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (!file) return;
          onChange(file);
        }}
      />
    </label>
  </Clickable>
);

export default ProfilePicture;
