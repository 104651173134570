import { Box, Button, TextField } from '@mui/material';
import Modal from 'components/Modal';
import Field from 'components/Field';
import { useState } from 'react';

export default function CustomTagDialog({
  open,
  onClose,
  maxWidth = 374,
  onSuccess,
}) {
  const [text, setText] = useState();
  const handleClose = (_, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    if (onClose) onClose();
    return true;
  };

  const handleConfirm = () => {
    setText('');
    if (!onSuccess) return;
    onSuccess(text);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box maxWidth={maxWidth}>
        <Field label="Add custom tag" mb={3}>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
          />
        </Field>
        <Box display="flex" justifyContent="flex-end">
          <Box
            component={Button}
            variant="contained"
            onClick={handleConfirm}
            mr={2}
          >
            Confirm
          </Box>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
