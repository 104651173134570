import { Box, Card, CardContent } from '@mui/material';
import Text from 'components/Text';

const Section = ({ title, children, ...props }) => (
  <Card sx={{ mb: 4 }} {...props}>
    <CardContent>
      <Box noValidate autoComplete="off" display="flex" flexDirection="column">
        <Text variant="h3" mb={3}>
          {title}
        </Text>
        {children}
      </Box>
    </CardContent>
  </Card>
);

export default Section;
