import { useRef, useState } from 'react';

export const useDebouncedInput = (defaultValue = '', timeout = 1000) => {
  const ref = useRef(defaultValue);
  const [text, setText] = useState(defaultValue);
  const [debounced, setDebounced] = useState(defaultValue);

  const handleChange = (e) => {
    const { value } = e.target;
    ref.current = value;
    setText(value);
    setTimeout(() => {
      setDebounced(ref.current);
    }, timeout);
  };

  const reset = () => {
    ref.current = '';
    setText('');
    setDebounced('');
  };

  return {
    value: text,
    debouncedValue: debounced,
    onChange: handleChange,
    reset,
  };
};
