import { Container, Grid, InputLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState, useEffect } from 'react';

const GrantFilters = ({ search, filters, onSearch, onChange }) => {
  const [searchQuery, setSearchQuery] = useState(search);

  const handleCategoryChanged = (event) => {
    onChange({
      ...filters,
      category: [event.target.value],
    });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  useEffect(() => {
    const res = onSearch(searchQuery);

    return () => {
      clearTimeout(res);
    };
  }, [searchQuery]);

  return (
    <Container>
      <Box sx={{ mb: 4 }}>
        <Grid xs={12} spacing={2} container>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                onChange={handleSearch}
                value={searchQuery}
                placeholder="Type keyword here"
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={filters.category?.[0] || ''}
                label="Category"
                onChange={handleCategoryChanged}
                fullWidth
              >
                <MenuItem value="">-</MenuItem>
                <MenuItem value="formula">Formula</MenuItem>
                <MenuItem value="federal">Federal</MenuItem>
                <MenuItem value="competitive">Competitive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default GrantFilters;
