import { createContext, useContext, useState } from 'react';

export const LayoutContext = createContext();

export const LayoutContextProvider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isFaqOpen, setIsFaqOpen] = useState(true);
  const [adminOpen, setAdminOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const toggleFaq = () => {
    setIsFaqOpen((prev) => !prev);
  };

  const toggleAdmin = () => {
    setAdminOpen((prev) => !prev);
  };

  const defaultContext = {
    drawerOpen,
    isFaqOpen,
    adminOpen,
    toggleDrawer,
    toggleFaq,
    toggleAdmin,
  };

  return (
    <LayoutContext.Provider value={defaultContext}>
      {children}
    </LayoutContext.Provider>
  );
};

export function useLayout() {
  return useContext(LayoutContext);
}
