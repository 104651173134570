import { Box } from '@mui/material';
import Text from 'components/Text';

const Field = ({ label, labelProps, children, ...props }) => (
  <Box {...props}>
    <Text variant="subtitle1" mb={1} {...labelProps}>
      {label}
    </Text>
    {children}
  </Box>
);

export default Field;
