import { Box, Button } from '@mui/material';
import Modal from 'components/Modal';
import { Body, Title2 } from 'components/Text';

export default function ConfirmLeavedialog({
  open,
  onClose,
  onConfirm,
  onSave,
}) {
  return (
    <Modal maxWidth={538} open={open} onClose={onClose}>
      <Box mb={3}>
        <Title2 mt={0} mb={3} fontFamily="Playfair Display">
          Alert
        </Title2>
        <Body my={0}>
          Are you sure you want to leave this page? What do you want to do with
          your data?
        </Body>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Box
          component={Button}
          variant="contained"
          px={4.5}
          py={2}
          sx={{ backgroundColor: 'background.button.dark' }}
          onClick={onSave}
          mr={2}
        >
          Save data
        </Box>
        <Button variant="text" onClick={onConfirm}>
          Leave and clear data
        </Button>
      </Box>
    </Modal>
  );
}
