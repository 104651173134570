import { styled, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { BRAND_COLORS } from 'theme';

const StyledText = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: BRAND_COLORS.darkGray,
    opacity: 0.5,
  },
  '& .MuiInputBase-root': {
    backgroundColor: BRAND_COLORS.white,
  },
});

const DatePicker = ({ textFieldProps, ...props }) => (
  <DesktopDatePicker
    inputFormat="MM/dd/yyyy"
    renderInput={(params) => <StyledText {...params} {...textFieldProps} />}
    {...props}
  />
);

export default DatePicker;
