/* eslint-disable import/prefer-default-export */
import { PersonOutline, InboxOutlined, Article } from '@mui/icons-material';
import { ReactComponent as Dashboard } from 'images/icons/dashboard.svg';
import { ReactComponent as Application } from 'images/icons/application.svg';
import { ReactComponent as Letter } from 'images/icons/letter.svg';
import { ReactComponent as Opportunities } from 'images/icons/opportunities.svg';
import { ReactComponent as Faq } from 'images/icons/faq.svg';

export const NAV_LINKS = [
  { label: 'Home', href: '/', icon: <Dashboard /> },
  { label: 'Grant Opportunities', href: '/grants', icon: <Opportunities /> },
  { label: 'Grant Templates', href: '/templates', icon: <Opportunities /> },
  { label: 'Manage Letters', href: '/my-letters', icon: <Letter /> },
  { label: 'Grant Applications', href: '/my-grants', icon: <Application /> },
  { label: 'FAQs', href: '/faqs', icon: <Faq /> },
  {
    label: 'Admin',
    isAdmin: true,
    icon: <InboxOutlined />,
    children: [
      {
        label: 'Users',
        href: '/admin/manage-users',
        icon: <PersonOutline />,
      },
      {
        label: 'Grants',
        href: '/admin/manage-grants',
        icon: <Article />,
      },
      {
        label: 'User Grants',
        href: '/admin/manage-user-grants',
        icon: <Article />,
      },
    ],
  },
];
