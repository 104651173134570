import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ file, pageProps, onFinishReadingCharCount }) => {
  const [numPages, setNumPages] = useState();
  const [, setCharCountInfo] = useState({
    length: 0,
    lastPage: 0,
  });

  const onDocumentLoadSuccess = ({ numPages: value }) => {
    setNumPages(value);
  };

  const onGetTextSuccess = (page) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    const pageCharCount = page.reduce((c, item) => (c += item.str.length), 0);
    setCharCountInfo((prev) => {
      const len = prev.length + pageCharCount;
      const idx = prev.lastPage + 1;
      if (idx === numPages) {
        onFinishReadingCharCount(len);
      }
      return {
        length: len,
        lastPage: idx,
      };
    });
  };

  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={500}
          onGetTextSuccess={
            onFinishReadingCharCount ? onGetTextSuccess : undefined
          }
          {...pageProps}
        />
      ))}
    </Document>
  );
};

export default PdfViewer;
