import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

export function useInfinitePageList({ key, keyData, pageSize, handler }) {
  const { data, isFetching, fetchNextPage, hasNextPage, error } =
    useInfiniteQuery(
      [key, keyData],
      async ({ queryKey, pageParam = 0 }) => {
        const [, queryObjects] = queryKey;
        const response = await handler({
          ...queryObjects,
          pageSize,
          page: pageParam,
        });
        const nextPageSize = (pageParam + 1) * pageSize;
        const nextPage =
          nextPageSize >= response?.total ? undefined : pageParam + 1;
        return {
          items: response?.items ?? [],
          total: response?.total ?? 0,
          nextPage,
          pageParam,
        };
      },
      {
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
      }
    );
  const items = useMemo(
    () =>
      data?.pages?.reduce((list, page) => {
        list.push(...page.items);
        return list;
      }, []) ?? [],
    [data?.pages]
  );

  const total = useMemo(() => {
    if (!data?.pages || data.pages.length === 0) return 0;
    return data.pages[data.pages.length - 1].total;
  }, [data?.pages]);

  return {
    data,
    total,
    fetchNextPage,
    hasNextPage,
    items,
    error,
    isLoading: isFetching,
  };
}
