import { Autocomplete, TextField } from '@mui/material';

const TagInput = ({ onChange, defaultValue, textFieldProps, ...props }) => {
  const handleChange = (e, values) => {
    e.stopPropagation();
    if (onChange) onChange(values);
  };

  return (
    <Autocomplete
      defaultValue={defaultValue}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} {...textFieldProps} />}
      options={[]}
      multiple
      freeSolo
      {...props}
    />
  );
};

export default TagInput;
