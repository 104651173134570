import { Box } from '@mui/material';

const Flex = ({ center = false, ...props }) => (
  <Box
    display="flex"
    alignItems={center ? 'center' : 'flex-start'}
    justifyContent={center ? 'center' : 'flex-start'}
    {...props}
  />
);

export default Flex;
