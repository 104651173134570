import { useState } from 'react';
import { Box, IconButton, MenuItem, Select, TextField } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import humanize from 'underscore.string/humanize';
import { GRANTS_FIELD_TYPES } from 'utils/constants';

const FieldItem = ({ item, onChange, onRemove, ...props }) => {
  const [name, setName] = useState(item?.name || '');
  const [type, setType] = useState(item?.type || GRANTS_FIELD_TYPES.TEXT);

  const handleNameChange = (e) => {
    if (!onChange) return;
    const { value } = e.target;
    setName(value);
    onChange({ name: value, type });
  };
  const handleTypeChange = (e) => {
    if (!onChange) return;
    const { value } = e.target;
    setType(value);
    onChange({ type: value, name });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', ...props }}>
      <TextField
        value={item?.name || ''}
        onChange={handleNameChange}
        placeholder="e.g. firstName"
        sx={{ mr: 2 }}
        fullWidth
      />
      <Select
        value={item?.type || GRANTS_FIELD_TYPES.TEXT}
        sx={{ mr: 2 }}
        onChange={handleTypeChange}
        fullWidth
      >
        {Object.values(GRANTS_FIELD_TYPES).map((value) => (
          <MenuItem key={value} value={value}>
            {humanize(value)}
          </MenuItem>
        ))}
      </Select>
      <IconButton onClick={onRemove}>
        <DeleteOutlineIcon color="error" />
      </IconButton>
    </Box>
  );
};

export default FieldItem;
