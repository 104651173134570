export const APP_CONFIG = {
  API_URL: process.env.REACT_APP_API_URL,
  S3_BUCKET_NAME: process.env.REACT_APP_S3_BUCKET,
  S3_REGION: process.env.REACT_APP_S3_REGION,
  S3_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY,
  S3_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

export const ORGANIZATION_TYPES = {
  FOR_PROFIT: 'for_profit',
  NON_PROFIT: 'non_profit',
};

export const DEFAULT_DATAGRID_CONFIG = {
  skip: 0,
  take: 10,
  sort: {
    createdDate: -1,
  },
};

export const AUTH_TOKEN_KEY = 'grants-app-auth';
export const AUTH_USER_KEY = 'grants-app-user';
export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const MAIN_6FG_SITE_URL = 'https://6figuregrants.com';
export const DRAWER_WIDTH = 340;
export const FAQ_WIDTH = 456;
