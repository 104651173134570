import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { getUserByIdQuery, updateUser } from 'api';
import Flex from 'components/Flex';
import Text, { Body, Title3 } from 'components/Text';
import Field from 'components/Field';
import Spinner from 'components/Spinner';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import DefaultProfilePicture from 'images/default-profile.png';
import ProfilePicture from './ProfilePicture';

const getDefaultData = (user) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  phone: user?.phone || '',
  isFullyPaid: user?.isFullyPaid || false,
  emailVerification: {
    isVerified: user?.emailVerification?.isVerified || false,
  },
  profilePictureUrl: user?.profilePictureUrl || DefaultProfilePicture,
});

const EditUsersPage = () => {
  const { id } = useParams();
  const { showSnackbar } = useSnackbar();
  const { data: user, isLoading: isInitLoading } = useQuery(
    getUserByIdQuery(id)
  );

  const { mutate: saveUser, isLoading: isSaving } = useMutation(
    (data) => updateUser(id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        showSnackbar({
          variant: SNACKBAR_VARIANTS.SUCCESS,
          message: 'Successfully saved user',
        });
      },
      onError: (err) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: err,
        });
      },
    }
  );

  const handleSaveUser = (formData) => {
    saveUser(formData);
  };

  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset,
    control,
  } = useForm({ defaultValues: getDefaultData(user) });

  useEffect(() => {
    if (!user) return;
    reset(getDefaultData(user));
  }, [user]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
      overflow="auto"
      px={3}
    >
      <Box component="form" onSubmit={handleSubmit(handleSaveUser)}>
        <Box component={Card} width={{ xs: '100%', md: 568 }} mb={4}>
          <CardContent>
            {(isInitLoading || !user) && <Spinner />}
            {user && (
              <Box
                noValidate
                autoComplete="off"
                display="flex"
                flexDirection="column"
              >
                <Text variant="h3" mb={3}>
                  Basic Information
                </Text>
                <Flex alignItems="center" mb={5}>
                  <Controller
                    name="profilePictureUrl"
                    control={control}
                    render={({ field }) => (
                      <ProfilePicture
                        url={field.value}
                        onChange={(response) => field.onChange(response)}
                      />
                    )}
                  />
                  <Flex
                    flexGrow={1}
                    flexDirection="column"
                    justifyContent="center"
                    ml={4}
                  >
                    <Title3 mt={0} mb={1}>
                      {user.email}
                    </Title3>
                    <Body my={0} color="grey.800">
                      {user.roles?.includes('curator')
                        ? 'Curator'
                        : 'Applicant'}
                    </Body>
                  </Flex>
                </Flex>
                <Field label="First Name" mb={2}>
                  <TextField
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message}
                    name="firstName"
                    {...register('firstName', {
                      required: {
                        value: true,
                        message: 'First name is required',
                      },
                    })}
                    fullWidth
                  />
                </Field>
                <Field label="Last Name" mb={2}>
                  <TextField
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message}
                    name="lastName"
                    {...register('lastName', {
                      required: {
                        value: true,
                        message: 'lastName is required',
                      },
                    })}
                    fullWidth
                  />
                </Field>
                <Field label="Contact" mb={2}>
                  <TextField
                    error={!!errors?.phone}
                    helperText={errors?.phone?.message}
                    name="phone"
                    {...register('phone')}
                    fullWidth
                  />
                </Field>
              </Box>
            )}
          </CardContent>
        </Box>

        <Card sx={{ mb: 4 }}>
          <CardContent>
            {(isInitLoading || !user) && <Spinner />}
            {user && (
              <Box
                noValidate
                autoComplete="off"
                display="flex"
                flexDirection="column"
              >
                <Text variant="h3" mb={3}>
                  Verification
                </Text>
                <Controller
                  name="isFullyPaid"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                          name="isFullyPaid"
                        />
                      }
                      label="Fully paid"
                    />
                  )}
                />
                <Controller
                  name="emailVerification.isVerified"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                          name="emailVerification.isVerified"
                        />
                      }
                      label="Email verified"
                    />
                  )}
                />
              </Box>
            )}
          </CardContent>
        </Card>

        <Box display="flex" my={3}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSaving}
            loadingIndicator="Saving..."
            size="large"
            disabled={!isDirty || isSaving}
            sx={{ px: 5 }}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Flex>
  );
};

export default EditUsersPage;
