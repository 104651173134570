import { Box, Card, CardContent, Grid, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Flex from 'components/Flex';
import Link from 'components/Link';
import Text, { Large } from 'components/Text';
import * as moment from 'moment';
import * as React from 'react';
import { GRANTS_TYPES } from 'utils/constants';

const Description = styled(Text)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box !important',
  '-webkit-line-clamp': '3 !important',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
});

const getLink = (grant) => {
  if (grant.type === GRANTS_TYPES.LINK) return grant.url;
  return `/${
    grant.type === GRANTS_TYPES.GRANT ? 'my-grants' : 'my-letters'
  }/new?grantId=${grant.id}`;
};

const GrantCard = ({ grant, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box sx={props} onClick={handleClickOpen}>
        <Box
          component={Card}
          height={{ xs: '100%', md: 344 }}
          sx={{ px: 2, py: 3, cursor: 'pointer' }}
        >
          <Box component={CardContent} height="100%">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box>
                <Large fontWeight={700}>{grant?.title}</Large>
                {grant?.description && (
                  <Description variant="body1" color="black" sx={{ mt: 2 }}>
                    {grant?.description}
                  </Description>
                )}
              </Box>
              <Box>
                <Text
                  variant="body2"
                  color="black"
                  display={'block'}
                  marginTop={3}
                >
                  Posted Date:
                </Text>
                <Text variant="body2" color="black" fontWeight={700}>
                  {moment(grant.createdDate).format('MMM DD, YYYY')}
                </Text>
                <Text
                  variant="body2"
                  color="black"
                  display={'block'}
                  marginTop={3}
                >
                  Estimated Total Program Funding:
                </Text>
                <Text variant="body2" color="black" fontWeight={700}>
                  ${grant.value.toFixed(2)}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{grant?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ mb: 4, overflow: 'auto' }}>
              {grant?.description && (
                <Text
                  variant="body2"
                  color="black"
                  display={'block'}
                  maxheight={'350px'}
                >
                  {grant?.description}
                </Text>
              )}
            </Box>
            <Grid xs={12} spacing={2} container>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Text variant="body2" color="black" display={'block'}>
                    Posted Date:
                  </Text>
                  <Text variant="body2" color="black" fontWeight={700}>
                    {moment(grant.createdDate).format('MMM DD, YYYY')}
                  </Text>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Text variant="body2" color="black" display={'block'}>
                    Estimated Total Program Funding:
                  </Text>
                  <Text variant="body2" color="black" fontWeight={700}>
                    ${grant.value.toFixed(2)}
                  </Text>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            sx={{ color: 'background.button.dark', p: 1.5, fontWeight: 500 }}
          >
            Close
          </Button>
          <Link href={getLink(grant)} variant="contained">
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'background.button.dark',
                p: 1.5,
                fontWeight: 500,
              }}
            >
              Use this grant template
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default GrantCard;
