import { Box, Button, Card, IconButton, styled, SvgIcon } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Flex from 'components/Flex';
import PdfViewer from 'components/PdfViewer';
import { Body, Title3 } from 'components/Text';
import { ReactComponent as DownloadIcon } from 'images/icons/download.svg';
import { ReactComponent as PrintIcon } from 'images/icons/print.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Navigation } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { BRAND_COLORS, getScrollbarStyle } from 'theme';
import useComponentDimensions from 'hooks/useComponentDimensions';

const MEASUREMENT = {
  DOCUMENT_CONTAINER: {
    paddingTop: 85,
  },
  ACTION_FOOTER: { height: 88, bottom: 46 },
};

const ArrowIcon = styled(({ reverse, ...props }) => <IconButton {...props} />)(
  ({ theme, reverse }) => ({
    color: theme.palette.grey[800],
    transform: reverse ? 'rotate(180deg)' : 'none',
    svg: {
      width: 36,
      height: 36,
    },
  })
);

const getPdfDocumentHeight = () =>
  `calc(100vh - ${[
    MEASUREMENT.DOCUMENT_CONTAINER.paddingTop,
    MEASUREMENT.ACTION_FOOTER.height,
    MEASUREMENT.ACTION_FOOTER.bottom,
    50,
    // eslint-disable-next-line no-return-assign, no-param-reassign
  ].reduce((a, b) => (a += b), 0)}px)`;

const DocumentContainer = styled(Box)({
  height: '100%',
  paddingTop: 85,
  paddingLeft: 72,
  paddingRight: 72,

  '& .react-pdf__Document': {
    ...getScrollbarStyle(),
    overflow: 'auto',
    height: getPdfDocumentHeight(),
    '& .react-pdf__Page': {
      height: '100%',
      '& .react-pdf__Page__svg': {
        height: '100% !important',
        width: '100% !important',
      },
    },
  },
});

const SwiperStyled = styled(Swiper)(({ theme, parentWidth }) => {
  let maxWidth = 600;
  if (parentWidth > 768) {
    maxWidth = 768;
  } else if (parentWidth > 900) {
    maxWidth = 768;
  } else if (parentWidth > 1200) {
    maxWidth = 1024;
  }

  return {
    maxWidth,
    height: '100%',
    '& .swiper-slide': {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(15),
    },
  };
});

const DocumentViewer = ({
  pages,
  type,
  file,
  isFile,
  isLoadingFile,
  loadingFileError,
  onDownload,
  onPrint,
  onCancel,
  isSaving,
}) => {
  const [charCount, setCharCount] = useState(0);
  const containerRef = useRef();
  const dimensions = useComponentDimensions(containerRef);

  const handleFinishReadingCharCount = (count) => {
    setCharCount(count);
  };

  const handleOnSwipe = (activeIndex) => {
    const currPage = pages[activeIndex];
    if (!currPage) return;
    const str = currPage.replace(/(<([^>]+)>)/gi, '');
    setCharCount(str.length);
  };

  useEffect(() => {
    if (!pages || pages.length === 0) {
      setCharCount(0);
      return;
    }
    const ct = pages[0].replace(/(<([^>]+)>)/gi, '');
    setCharCount(ct.length);
  }, [pages]);

  const hasSelectedGrant = useMemo(() => {
    if (isFile && file) return true;
    if (!isFile && pages && pages.length > 0) return true;
    return false;
  }, [isFile, file, pages]);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        maxHeight: '100%',
        backgroundColor: BRAND_COLORS.white,
        width: '100%',
      }}
    >
      {!isFile && pages && pages.length > 0 && (
        <Box width="100%" height="100%" ref={containerRef}>
          <SwiperStyled
            slidesPerView={1}
            modules={[Navigation]}
            onSlideChange={({ activeIndex }) => handleOnSwipe(activeIndex)}
            parentWidth={dimensions?.width}
            navigation={{
              nextEl: '.doc-right-arrow',
              prevEl: '.doc-left-arrow',
            }}
          >
            {pages.map((page, i) => (
              <SwiperSlide key={i}>
                <Box height="100%" bgcolor="white">
                  <Box
                    width="100%"
                    height="100%"
                    minWidth={768}
                    mx="auto"
                    dangerouslySetInnerHTML={{
                      __html: page,
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </SwiperStyled>
        </Box>
      )}
      {isFile && file && (
        <DocumentContainer>
          <PdfViewer
            file={file}
            pageProps={{ height: '100vh', scale: 1.6 }}
            onFinishReadingCharCount={handleFinishReadingCharCount}
          />
        </DocumentContainer>
      )}
      {!hasSelectedGrant && (
        <Flex fontFamily="Playfair Display" sx={{ height: '100%' }} center>
          <Title3 color={BRAND_COLORS.darkGray} sx={{ opacity: 0.3 }}>
            {loadingFileError && `Unable to display ${type}`}
            {!loadingFileError && (
              <>
                {isLoadingFile ? `Loading ${type}...` : `Select a ${type} type`}
              </>
            )}
          </Title3>
        </Flex>
      )}
      <Box position="absolute" top={72} right={121} zIndex={1000}>
        <Card sx={{ px: 3.625, py: 1.375, borderRadius: 2 }}>
          <Flex alignItems="center">
            <Body my={0}>{charCount} characters</Body>
          </Flex>
        </Card>
      </Box>
      {hasSelectedGrant && pages?.length > 1 && (
        <>
          <Box position="absolute" top="50%" left={16} zIndex={1000}>
            <ArrowIcon className="doc-left-arrow" reverse>
              <ArrowForwardIosIcon />
            </ArrowIcon>
          </Box>
          <Box position="absolute" top="50%" right={16} zIndex={1000}>
            <ArrowIcon className="doc-right-arrow">
              <ArrowForwardIosIcon />
            </ArrowIcon>
          </Box>
        </>
      )}
      <Box
        position="sticky"
        bottom={46}
        px={2.75}
        pb={5.75}
        height={88}
        zIndex={1000}
      >
        <Card
          sx={{
            px: 3,
            py: 2,
            borderRadius: 2,
          }}
        >
          <Flex
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ gap: 1, flexDirection: { xs: 'column', md: 'row' } }}
          >
            <Flex
              flexWrap="wrap"
              sx={{
                gap: 2.375,
                flexDirection: { xs: 'column', md: 'row' },
                width: { xs: '100%', md: 'auto' },
              }}
            >
              <Button
                size="large"
                variant="contained"
                disabled={!file}
                onClick={onDownload}
                startIcon={
                  <SvgIcon sx={{ height: 16 }}>
                    <DownloadIcon />
                  </SvgIcon>
                }
                sx={{ width: { xs: '100%', md: 'auto' } }}
              >
                Download
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={onPrint}
                startIcon={
                  <SvgIcon sx={{ height: 16 }}>
                    <PrintIcon />
                  </SvgIcon>
                }
                sx={{ width: { xs: '100%', md: 'auto' } }}
              >
                Print
              </Button>
            </Flex>
            <Flex
              justifyContent="end"
              flexWrap="wrap"
              sx={{
                gap: 2.375,
                flexDirection: { xs: 'column', md: 'row' },
                width: { xs: '100%', md: 'auto' },
              }}
            >
              <Button
                size="large"
                sx={{
                  color: 'background.button.dark',
                  width: { xs: '100%', md: 'auto' },
                }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="contained"
                sx={{
                  backgroundColor: 'background.button.dark',
                  width: { xs: '100%', md: 'auto' },
                }}
                loading={isSaving}
                loadingIndicator="Saving…"
                type="submit"
              >
                Save Letter
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Box>
    </Box>
  );
};

export default DocumentViewer;
