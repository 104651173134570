import { useMutation, useQuery } from 'react-query';
import { getUserByToken, updateUser } from 'api';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { useAuth } from 'contexts/Auth';
import UserForm from './Form';

const getUserByTokenQuery = (user) => ({
  queryKey: () => ['getUserByTokenQuery'],
  queryFn: getUserByToken,
  enabled: !!user,
});

const UserProfilePage = () => {
  const { user: authUser, refreshUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { data: user, isLoading } = useQuery(getUserByTokenQuery(authUser));
  const { mutate, isLoading: isSaving } = useMutation(
    (data) => updateUser(user.id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        showSnackbar({
          message: 'Successfully updated your account',
          variant: SNACKBAR_VARIANTS.SUCCESS,
        });
        refreshUser();
      },
      onFailure: (err) => {
        showSnackbar({
          message: err?.message || 'Failed to update your account',
          variant: SNACKBAR_VARIANTS.ERROR,
        });
      },
    }
  );

  if (isLoading || !user) return null;

  return (
    <UserForm user={user} onSubmit={mutate} isLoading={isLoading || isSaving} />
  );
};
export default UserProfilePage;
