import { useAuth } from 'contexts/Auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useValidateUserAccess() {
  const { user, isInitialized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInitialized) return;

    if (!user) {
      navigate('/auth/sign-in');
      return;
    }

    // If user is a curator/admin, redirect to the dashboard
    if (user.roles?.includes('curator')) {
      return;
    }

    // If user has not paid yet, redirect to verify payment page
    if (!user.isFullyPaid) {
      navigate('/auth/verify-account');
      return;
    }

    // If user has no organizations yet, proceed to create organization page
    if (!user?.organizations || user.organizations.length === 0) {
      navigate('/auth/org-confirmation');
    }
  }, [user, isInitialized]);

  return { isInitialized };
}
