import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, Card, CircularProgress, Button, TextField } from '@mui/material';
import { forgotPassword } from 'api';
import { Body, Title2 } from 'components/Text';
import { useSnackbar, SNACKBAR_VARIANTS } from 'components/Snackbar';
import Flex from 'components/Flex';
import Link from 'components/Link';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const { showSnackbar } = useSnackbar();
  const { mutate, isLoading, error } = useMutation(forgotPassword, {
    onSuccess: (data) => {
      if (!data) return;
      setEmail('');
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: 'Reset Password Email is sent your email address.',
      });
    },
  });

  const onSubmit = () => {
    if (!email) {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: 'Please enter email address',
      });
      return;
    }
    mutate({ email });
  };

  useEffect(() => {
    if (!error) return;
    showSnackbar({
      variant: SNACKBAR_VARIANTS.ERROR,
      message: error,
    });
  }, [error]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      position="relative"
    >
      <Box
        maxWidth={{ xs: 'initial', md: 550 }}
        minWidth={{ xs: 'initial', md: 550 }}
        width={{ xs: '90%', md: '100%' }}
        mx="auto"
        mt={{ xs: 4, md: 5.625 }}
        mb={{ xs: 6.25, md: 12.5 }}
      >
        <Card>
          <Box px={{ xs: 3.75, md: 7 }} my={5.625} width="100%">
            <Title2 fontFamily="Playfair Display" mt={0} mb={5}>
              Forgot Password
            </Title2>
            <Body color="grey.800" my={0}>
              Enter the email address you used when you signed up and we&apos;ll
              send you instructions to reset your password.
            </Body>
            <Box my={4}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                placeholder="youremail@domain.com"
                label="Email Address"
                fullWidth
              />
            </Box>
            <Flex alignItems="center" my={4}>
              <Body my={0} color="grey.600" bold mr={0.5}>
                Remembered your password?
              </Body>
              <Link href="/auth/sign-in" underline="none">
                <Body my={0} color="primary.main" bold>
                  Sign In
                </Body>
              </Link>
            </Flex>
            <Button
              onClick={onSubmit}
              large
              disabled={isLoading}
              variant="contained"
              sx={{ backgroundColor: 'background.button.dark' }}
            >
              Send Reset Instructions
              {isLoading && (
                <Box
                  component={CircularProgress}
                  ml={2}
                  size={16}
                  color="inherit"
                />
              )}
            </Button>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
