import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as qs from 'qs';
import {
  styled,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import Flex from 'components/Flex';
import { Body, Title3 } from 'components/Text';
import Bg1 from 'images/bg-1.png';

const Image = styled((props) => <Box {...props} />)(() => ({
  width: 240,
  height: 341,
  backgroundImage: `url('${Bg1}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderTopLeftRadius: 30,
  borderBottomLeftRadius: 30,
}));

const SearchCard = (props) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [category, setCategory] = useState('');

  const handleQueryChanged = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const handleCategoryChanged = (e) => {
    const { value } = e.target;
    setCategory(value);
  };

  const handleSearch = () => {
    const q = qs.stringify({
      q: query,
      filter: {
        category: [category],
      },
    });
    navigate(`/grants?${q}`);
  };

  return (
    <Flex
      width="100%"
      height={341}
      borderRadius={5}
      alignItems="center"
      bgcolor="common.white"
      boxShadow={'rgb(0 0 0 / 24%) 0px 6px 16px'}
      {...props}
    >
      <Image />
      <Flex flexGrow={1} width="100%" px={5.5} py={6.5}>
        <Box width="100%">
          <Box px={3}>
            <Title3 color="grey.800" mt={0} mb={1}>
              Search for grant opportunities
            </Title3>
            <Body color="grey.800" mt={0} mb={1.25}>
              Mauris non tempor quam, et lacinia sapien. Mauris accumsan eros.
            </Body>
          </Box>
          <Box mb={4}>
            <TextField
              fullWidth
              onChange={handleQueryChanged}
              value={query}
              placeholder="Type keyword here"
            />
          </Box>
          <Flex alignItems="center">
            <Flex flexGrow={1} mr={{ xs: 0, md: 2.625 }} mb={{ xs: 2, md: 0 }}>
              <Select
                value={category || ''}
                label="Category"
                onChange={handleCategoryChanged}
                fullWidth
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="formula">Formula</MenuItem>
                <MenuItem value="federal">Federal</MenuItem>
                <MenuItem value="competitive">Competitive</MenuItem>
              </Select>
            </Flex>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'background.button.dark', py: 1.5 }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SearchCard;
