import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => (
  <Box display="flex" justifyContent="center" sx={{ padding: 5 }}>
    <CircularProgress />
  </Box>
);

export default Spinner;
