/* eslint-disable arrow-body-style */
import { Box, Button, Card, CardContent, TextField } from '@mui/material';
import { deleteUserGrant, downloadUserGrant, getUserGrantsQuery } from 'api';
import CompactTable from 'components/CompactTable';
import Actions from 'components/CompactTable/Actions';
import Flex from 'components/Flex';
import Link from 'components/Link';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { Body, Title2 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import { format, isValid } from 'date-fns';
import { useDebouncedInput } from 'hooks/useDebouncedInput';
import { usePrint } from 'hooks/usePrint';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { BRAND_COLORS } from 'theme';
import { humanize } from 'underscore.string';
import { GRANTS_TYPES } from 'utils/constants';
import { downloadFile } from 'utils/helpers/misc';
import DeleteDialog from './DeleteDialog';

const MyGrantsPage = ({ type }) => {
  const { user } = useAuth();
  const execPrint = usePrint();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const heading = useMemo(
    () => `Manage ${type === GRANTS_TYPES.GRANT ? 'Grants' : 'Letters'}`,
    [type]
  );
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const writeLabel = useMemo(() => `Write a ${type}`, [type]);
  const writeUrl = useMemo(
    () => (type === GRANTS_TYPES.GRANT ? '/my-grants/new' : '/my-letters/new'),
    [type]
  );
  const {
    value,
    debouncedValue: search,
    onChange: onSearchChanged,
    reset,
  } = useDebouncedInput();
  const { data: userGrants, refetch } = useQuery(
    getUserGrantsQuery({
      filter: { userId: user.id, grant: { type } },
      search,
    })
  );
  const items = useMemo(() => userGrants?.items ?? [], [userGrants?.items]);
  const { mutate: downloadPdf, isLoading: isDownloading } = useMutation(
    downloadUserGrant,
    {
      onSuccess: async (res) => {
        if (!res) return;
        downloadFile(res, 'user-grant');
      },
    }
  );
  const { mutate: deleteGrant, isLoading: isDeleting } = useMutation(
    deleteUserGrant,
    {
      onSuccess: async (res) => {
        if (!res) return;
        showSnackbar({
          message: 'Successfully deleted!',
          variant: SNACKBAR_VARIANTS.SUCCESS,
        });
        setIsDeleteOpen(false);
        setSelected(null);
        refetch();
      },
    }
  );
  const handleDownload = (id, event) => {
    event.stopPropagation();
    downloadPdf(id);
  };
  const handlePrint = (row, event) => {
    event.stopPropagation();
    execPrint(row.grant, row.fields);
  };
  const columns = useMemo(() => {
    if (!items) return [];
    return [
      {
        field: 'title',
        headerName: 'Title',
        flex: 1,
        renderCell: ({ row }) => (
          <Body my={0}>{row.grant?.title || 'N/A'}</Body>
        ),
      },
      {
        field: 'category',
        headerName: 'Category',
        flex: 1,
        renderCell: ({ row }) => (
          <Body my={0}>{humanize(row.grant?.category) || 'N/A'}</Body>
        ),
      },
      {
        field: 'createdDate',
        headerName: 'Date Created',
        flex: 1,
        renderCell: ({ row }) => (
          <Body my={0}>
            {isValid(new Date(row.createdDate))
              ? format(new Date(row.createdDate), 'MMM dd, yyyy')
              : 'N/A'}
          </Body>
        ),
      },
      {
        field: 'controls',
        headerName: 'Controls',
        flex: 1,
        renderCell: ({ row }) => (
          <Actions
            onDownload={(event) => handleDownload(row.id, event)}
            isDownloading={isDownloading}
            onPrint={(ev) => handlePrint(row, ev)}
            onDelete={(ev) => {
              ev.stopPropagation();
              setIsDeleteOpen(true);
              setSelected(row);
            }}
            isDeleting={isDeleting}
          />
        ),
      },
    ];
  }, [items]);

  const handleOnRowClick = (data) => {
    const { row } = data;
    if (!row) return;
    const { id } = row;
    navigate(`/my-${type}s/${id}`);
  };

  useEffect(() => reset(), [type]);

  return (
    <>
      <Box>
        <Title2
          fontFamily="Playfair Display"
          ml={2}
          mb={5.5}
          color={BRAND_COLORS.darkGray}
        >
          {heading}
        </Title2>
        <Flex alignItems="center" mb={5}>
          <Flex alignItems="center" flexGrow={1}>
            <TextField
              value={value}
              placeholder={`Search ${type}`}
              sx={{ width: 413 }}
              onChange={onSearchChanged}
            />
          </Flex>
          <Link href={writeUrl}>
            <Button size="small" variant="contained">
              {writeLabel}
            </Button>
          </Link>
        </Flex>
        <Card>
          <CardContent>
            <CompactTable
              columns={columns}
              rows={items}
              onRowClick={handleOnRowClick}
            />
          </CardContent>
        </Card>
      </Box>
      {isDeleteOpen && (
        <DeleteDialog
          open={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          onConfirm={() => deleteGrant(selected.id)}
          type={type}
        />
      )}
    </>
  );
};

export default MyGrantsPage;
