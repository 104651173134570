import { Typography, Box, Button } from '@mui/material';
import Modal from 'components/Modal';

export default function DeleteDialog({
  open,
  onClose,
  objectName,
  maxWidth = 374,
  onDelete,
}) {
  const handleClose = (_, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    if (onClose) onClose();
    return true;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box maxWidth={maxWidth}>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Typography variant="h4">
            Are you sure you want to delete {objectName}?
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box component={Button} variant="contained" onClick={onDelete} mr={2}>
            Confirm
          </Box>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
