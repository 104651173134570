/* eslint-disable no-console */
import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { getToken, getUserByToken, register as registerApi } from 'api';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { AUTH_TOKEN_KEY } from 'utils/constants/global';
import {
  removeAuthTokenFromCookie,
  setAuthCookie,
} from 'utils/helpers/auth.helpers';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [user, setUser] = useState();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [signupErrors, setSignupErrors] = useState();

  const fullName = useMemo(() => {
    if (!user) return 'Not available';
    const name = `${user.firstName} ${user.lastName}`;
    if (!name.trim()) return 'Not available';
    return name;
  }, [user]);

  const refreshUser = async (navigateTo) => {
    try {
      const userByToken = await getUserByToken();
      if (!userByToken) return;
      setUser(userByToken);
      if (!userByToken.isFullyPaid) {
        navigate('/auth/verify-account');
        return;
      }
      if (
        !userByToken?.organizations ||
        userByToken.organizations.length === 0
      ) {
        navigate('/auth/org-confirmation');
      }

      if (navigateTo) navigate(navigateTo);
    } catch (e) {
      console.error(e);
    } finally {
      if (!isInitialized) setIsInitialized(true);
    }
  };

  const signIn = async (email, password, navigateTo) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const res = await getToken(email, password);
      if (!res.access_token) throw new Error();
      setAuthCookie(res.access_token);
      await refreshUser(navigateTo || '/');
    } catch (err) {
      showSnackbar({
        message: err?.message ?? 'Invalid credentials',
        variant: SNACKBAR_VARIANTS.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = () => {
    setUser(undefined);
    removeAuthTokenFromCookie(AUTH_TOKEN_KEY);
    navigate('/auth/sign-in');
  };

  const { mutate: register, isLoading: isRegisterLoading } = useMutation(
    registerApi,
    {
      onMutate: () => {
        setSignupErrors(undefined);
      },
      onSuccess: async (_, data) => {
        if (!data) return;
        const { email, password } = data;
        await signIn(email, password, '/auth/verify-account');
      },
      onError: (error) => {
        setSignupErrors((prev) => ({ ...prev, ...error }));
      },
    }
  );

  useEffect(() => {
    refreshUser();
  }, []);

  const defaultContext = {
    isInitialized,
    isLoading,
    register,
    isRegisterLoading,
    signupErrors,
    user,
    fullName,
    signIn,
    signOut,
    refreshUser,
  };

  return (
    <AuthContext.Provider value={defaultContext}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
