import React, { useEffect, useState, useRef } from 'react';
import * as qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroller';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getGrants } from 'api';
import Flex from 'components/Flex';
import { Large } from 'components/Text';
import { useLayout } from 'contexts/Layout';
import { useInfinitePageList } from 'hooks';
import { removeEmptyValuesFromArr } from 'utils/helpers/misc';
import GrantFilters from '../Grants/GrantFilters';
import TemplateCard from './TemplateCard';

const DEFAULT_PAGE_SIZE = 3;

async function queryGrants({ q, sort, limit, filter, pageSize, page = 0 }) {
  const trimmedFilter = { ...filter };
  Object.keys(trimmedFilter).forEach((s) => {
    if (Array.isArray(trimmedFilter[s])) {
      trimmedFilter[s] = removeEmptyValuesFromArr(trimmedFilter[s]);
    }
  });
  const res = await getGrants({
    q,
    limit,
    filter: trimmedFilter,
    sort: sort ? { [sort]: -1 } : undefined,
    skip: page * pageSize,
  });
  return res;
}

const getGridSize = (size, isFaqOpen, isDrawerOpen) => {
  switch (size) {
    case 'lg': {
      if (isDrawerOpen && isFaqOpen) return 12;
      if (isDrawerOpen || isFaqOpen) return 6;
      return 4;
    }
    case 'md':
    case 'sm': {
      if (isDrawerOpen) return 12;
      return 6;
    }
    default:
      return 12;
  }
};

const GridScroller = React.forwardRef((props, ref) => (
  <Grid container spacing={2} sx={{ px: 2 }} {...props} ref={ref} />
));

const TemplatesPage = () => {
  const { drawerOpen, isFaqOpen } = useLayout();
  const searchRef = useRef('');
  const location = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    category: [],
    subcategory: [],
    tags: [],
    value: [],
    type: 'link',
    status: 'published',
  });

  const { items, isLoading, fetchNextPage, hasNextPage, total } =
    useInfinitePageList({
      key: 'public-grants',
      keyData: {
        limit: DEFAULT_PAGE_SIZE,
        sort: 'createdDate',
        filter: filters,
        q: searchRef.current,
      },
      pageSize: DEFAULT_PAGE_SIZE,
      handler: queryGrants,
    });

  const handleFilterChange = (value) => {
    const query = qs.stringify({ filter: value, q: searchRef.current });
    navigate(`/templates?${query}`);
  };

  const handleSearchChanged = (value) => {
    searchRef.current = value;

    return setTimeout(() => {
      const query = qs.stringify({ filter: filters, q: searchRef.current });
      navigate(`/templates?${query}`);
    }, 1000);
  };

  const handleLoadMore = () => {
    if (!hasNextPage || isLoading) return;
    fetchNextPage();
  };

  useEffect(() => {
    if (!location.search) return;
    const searchParams = qs.parse(location.search.substring(1));
    if (!searchParams) return;
    const { q, ...rest } = searchParams;
    searchRef.current = q;
    if (rest?.filter) setFilters(rest.filter);
  }, [location.search]);

  return (
    <Flex flexDirection="column" height="100%" overflow="auto">
      <Typography
        variant="h1"
        marginBottom={5}
        fontFamily="Playfair Display"
        fontWeight={600}
      >
        Grant Templates
      </Typography>
      <Typography variant="h5" my={5}>
        'Adipiscing elit duis tristique sollicitudin nibh sit amet commodo
        nulla'
      </Typography>
      <Box
        position="sticky"
        width="100%"
        alignSelf="flex-start"
        top={0}
        bgcolor="common.white"
      >
        <GrantFilters
          search={searchRef.current}
          onSearch={handleSearchChanged}
          filters={filters}
          onChange={handleFilterChange}
        />
      </Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mt={{ xs: 5, md: 10 }}
        mb={{ xs: 3, md: 5 }}
      >
        <Typography
          variant="h2"
          my={0}
          fontFamily="Playfair Display"
          fontWeight={500}
        >
          Featured Templates
        </Typography>
        <Large fontFamily="Playfair Display" my={0} pr={2} color="grey.600">
          {items.length} / {total}
        </Large>
      </Flex>
      <InfiniteScroll
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={hasNextPage}
        useWindow={false}
        element={GridScroller}
        threshold={0}
      >
        {items.map((grant, index) => (
          <Grid
            item
            xs={getGridSize('xs', isFaqOpen, drawerOpen)}
            sm={getGridSize('sm', isFaqOpen, drawerOpen)}
            lg={getGridSize('lg', isFaqOpen, drawerOpen)}
            key={index}
          >
            <TemplateCard key={grant.id} mb={2} grant={grant} />
          </Grid>
        ))}
        {isLoading && (
          <Skeleton count={5} height="290px" width="100%" duration={0.5} />
        )}
      </InfiniteScroll>
    </Flex>
  );
};

export default TemplatesPage;
