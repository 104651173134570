import { useForm } from 'react-hook-form';
import { Box, Button, Card, CardContent, TextField } from '@mui/material';
import { useAuth } from 'contexts/Auth';
import Flex from 'components/Flex';
import { Title2, Body } from 'components/Text';
import Link from 'components/Link';
import { VALIDATORS } from 'utils/constants/validators';

const SignIn = () => {
  const { signIn } = useAuth();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = ({ email, password }) => {
    signIn(email, password);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Card width={{ xs: '100%', md: '550' }}>
        <Box component={CardContent} px={6.875} py={7.375}>
          <Title2 fontFamily="Playfair Display" mt={0} mb={4.5}>
            Welcome back!
          </Title2>
          <Box mb={2.5}>
            <Box
              sx={{
                '& .MuiTextField-root': { m: 1 },
              }}
              noValidate
              autoComplete="off"
              display="flex"
              flexDirection="column"
            >
              <TextField
                label="Email/Username"
                error={!!errors?.email}
                helperText={errors?.email?.message}
                placeholder="Enter your username or email address"
                name="email"
                {...register('email', VALIDATORS.email)}
              />
              <TextField
                label="Password"
                error={!!errors?.password}
                helperText={errors?.password?.message}
                placeholder="Enter password"
                name="password"
                type="password"
                {...register('password', VALIDATORS.password)}
              />
            </Box>
          </Box>
          <Flex alignItems="center" mb={4}>
            <Body my={0} bold color="grey.600" mr={0.5}>
              Having trouble logging in?
            </Body>
            <Link href="/auth/forgot-password" underline="none">
              <Body my={0} color="primary.main" bold>
                Forgot Password
              </Body>
            </Link>
          </Flex>
          <Box mb={4}>
            <Button
              sx={{ backgroundColor: 'background.button.dark' }}
              type="submit"
              variant="contained"
            >
              Login
            </Button>
          </Box>
          <Flex alignItems="center" mb={4}>
            <Body my={0} bold color="grey.600" mr={0.5}>
              Not a member yet?
            </Body>
            <Link href="/auth/sign-up" underline="none">
              <Body my={0} color="primary.main" bold>
                Sign up
              </Body>
            </Link>
          </Flex>
        </Box>
      </Card>
    </Box>
  );
};

export default SignIn;
