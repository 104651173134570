import * as qs from 'qs';
import callApi from 'api/callApi';

const KEYS = {
  userById: (id) => [`user_${id}`, id],
};

export const getUsers = (filters) => {
  const query = qs.stringify(filters);
  return callApi(`/users?${query}`, {
    method: 'GET',
    needsAuthToken: true,
  });
};

const getUserById = ({ queryKey }) => {
  const [, id] = queryKey;
  return callApi(`/users/${id}`, {
    method: 'GET',
    needsAuthToken: true,
  });
};

export const getUserByIdQuery = (id) => ({
  queryKey: KEYS.userById(id),
  queryFn: getUserById,
  cacheTime: 0,
});

export const updateUser = (id, data) =>
  callApi(`/users/${id}`, {
    method: 'PATCH',
    data,
    needsAuthToken: true,
  });

export const saveOrganization = (id, data) =>
  callApi(`/users/${id}/organizations`, {
    method: 'POST',
    data,
    needsAuthToken: true,
  });
