import { useEffect, useState, useContext } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useBlocker(blocker, confirmed = false, when = true) {
  const [location, setLocation] = useState(null);
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return undefined;
    const unblock = navigator.block((tx) => {
      setLocation(tx.location);
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when, confirmed]);

  useEffect(() => {
    if (!confirmed || !location) return;
    navigator.push(location.pathname);
  }, [confirmed, location]);
}

export default function usePrompt(onBlock, confirmed = false, when = true) {
  const blocker = (tx) => {
    if (confirmed) {
      tx.retry();
      return;
    }
    if (onBlock) {
      onBlock();
    }
  };
  useBlocker(blocker, confirmed, when);
}
