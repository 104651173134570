import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import humanize from 'underscore.string/humanize';
import underscored from 'underscore.string/underscored';
import {
  Autocomplete,
  Box,
  Grid,
  lighten,
  styled,
  SvgIcon,
  TextField,
} from '@mui/material';
import { downloadUserGrant, getGrantsQuery, previewGrant } from 'api';
import { GRANTS_FIELD_TYPES, GRANTS_TYPES } from 'utils/constants';
import { downloadFile, replaceVariables } from 'utils/helpers/misc';
import { useSaveMyGrant } from 'hooks/useSaveMyGrant';
import { BRAND_COLORS, getScrollbarStyle } from 'theme';
import DatePicker from 'components/DatePicker';
import { ReactComponent as ArrowIcon } from 'images/icons/arrow.svg';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { Body, Title2 } from 'components/Text';
import UploadFile from 'components/UploadFile';
import usePrompt from 'hooks/useBlocker';
import { usePrint } from 'hooks/usePrint';
import DocumentViewer from './DocumentViewer';
import ConfirmLeaveDialog from './ConfirmLeaveDialog';

const StyledInput = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: BRAND_COLORS.darkGray,
    opacity: 0.5,
  },
  '& .MuiInputBase-root': {
    backgroundColor: BRAND_COLORS.white,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiInputBase-root': {
    backgroundColor: `${BRAND_COLORS.white} !important`,
  },
  '& .MuiIconButton-root:hover': {
    background: 'transparent',
    svg: {
      color: lighten('#000', 0.5),
    },
  },
});

const FieldText = ({ onChange, ...props }) => {
  const handleChange = (e) => {
    if (!onChange) return;
    onChange(e.target.value);
  };
  return <StyledInput onChange={handleChange} fullWidth {...props} />;
};

function getDefaultValue(grant) {
  const dateFields =
    grant?.fields?.filter(
      (f) => f.type === GRANTS_FIELD_TYPES.DATE && !f.value
    ) ?? [];
  for (const field of dateFields) {
    field.value = new Date();
  }
  return grant;
}

const GrantSelector = ({ type, defaultGrant, onChange }) => {
  const [selectedGrant, setSelectedGrant] = useState(defaultGrant);
  const { data: grantsData } = useQuery(getGrantsQuery({ filter: { type } }));
  const options = useMemo(() => grantsData?.items ?? [], [grantsData?.items]);

  const handleChange = (_, value) => {
    setSelectedGrant(value);
    if (onChange) onChange(value);
  };

  return (
    <>
      <Title2
        fontFamily="Playfair Display"
        ml={2}
        mb={5.5}
        color={BRAND_COLORS.darkGray}
      >
        {`Write a ${type}`}
      </Title2>
      <StyledAutocomplete
        disablePortal
        options={options}
        getOptionLabel={(option) => option.title}
        value={selectedGrant}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label={`Type of ${type}`} fullWidth />
        )}
        popupIcon={
          <SvgIcon sx={{ height: 14.9 }}>
            <ArrowIcon />
          </SvgIcon>
        }
        sx={{ mb: 1 }}
      />
    </>
  );
};

const GrantForm = ({ fields, attachments }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box pt={6.5}>
      <Body>Fill-in unique fields to populate the letter</Body>
      {fields.map((fieldItem, index) => (
        <Box key={fieldItem.id} sx={{ mb: 4 }}>
          <Controller
            name={`fields.${index}.value`}
            control={control}
            rules={{
              required: {
                value: true,
                message: `${humanize(fieldItem.name)} is required`,
              },
            }}
            render={({ field }) => (
              <>
                {fieldItem.type === GRANTS_FIELD_TYPES.TEXT && (
                  <FieldText
                    value={field.value}
                    onChange={field.onChange}
                    error={!!errors?.fields?.[index]?.value}
                    helperText={errors?.fields?.[index]?.value?.message}
                    label={humanize(fieldItem.name)}
                  />
                )}
                {fieldItem.type === GRANTS_FIELD_TYPES.NUMBER && (
                  <FieldText
                    value={field.value}
                    onChange={field.onChange}
                    type="number"
                    error={!!errors?.fields?.[index]?.value}
                    helperText={errors?.fields?.[index]?.value?.message}
                    label={humanize(fieldItem.name)}
                  />
                )}
                {fieldItem.type === GRANTS_FIELD_TYPES.TEXTAREA && (
                  <FieldText
                    value={field.value}
                    onChange={field.onChange}
                    rows={4}
                    multiline
                    error={!!errors?.fields?.[index]?.value}
                    helperText={errors?.fields?.[index]?.value?.message}
                    label={humanize(fieldItem.name)}
                  />
                )}
                {fieldItem.type === GRANTS_FIELD_TYPES.DATE && (
                  <DatePicker
                    value={field.value}
                    onChange={field.onChange}
                    textFieldProps={{
                      error: !!errors?.fields?.[index]?.value,
                      helperText: errors?.fields?.[index]?.value?.message,
                      label: humanize(fieldItem.name),
                      fullWidth: true,
                    }}
                  />
                )}
                {fieldItem.type === GRANTS_FIELD_TYPES.FILE && (
                  <UploadFile
                    fieldId={`file-${fieldItem.id}`}
                    useImagePreview={false}
                    accept="*"
                    url={field.value?.name}
                    onChange={field.onChange}
                    textFieldProps={{
                      error: !!errors?.fields?.[index]?.value,
                      helperText: errors?.fields?.[index]?.value?.message,
                      label: humanize(fieldItem.name),
                    }}
                  />
                )}
              </>
            )}
          />
        </Box>
      ))}

      {!!attachments?.length && (
        <>
          <Body mt={5}>Attach required files</Body>
          {attachments.map((attachment, index) => (
            <Box key={attachment.id} sx={{ mb: 4 }}>
              <Controller
                name={`attachments.${index}.value`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${attachment.label} is required`,
                  },
                }}
                render={({ field }) => (
                  <UploadFile
                    fieldId={`attachment-${attachment.id}`}
                    useImagePreview={false}
                    accept="*"
                    url={field.value?.name || attachment.value}
                    onChange={field.onChange}
                    textFieldProps={{
                      error: !!errors?.attachments?.[index]?.value,
                      helperText: errors?.attachments?.[index]?.value?.message,
                      label: attachment.label,
                    }}
                  />
                )}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

/*
required
  type: "grant" | "letter"

optional
  set either userGrant (for existing) or grant (new with set pre selected)
*/
const EditorPage = ({ type, userGrant, grant, onUpdate }) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [file, setFile] = useState();
  const [isLeaveOpen, setIsLeaveOpen] = useState(false);
  const [isLeaveConfirmed, setIsLeaveConfirmed] = useState(false);
  const [selectedGrant, setSelectedGrant] = useState(userGrant?.grant, grant);
  const execPrint = usePrint(selectedGrant);
  const methods = useForm({ defaultValues: getDefaultValue(selectedGrant) });
  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { isDirty },
    watch,
  } = methods;
  const { fields } = useFieldArray({
    control,
    name: 'fields',
  });
  const { fields: attachments } = useFieldArray({
    control,
    name: 'attachments',
  });

  const watchedFields = watch('fields');

  const {
    mutate: preview,
    isLoading: isDownloadingPreview,
    error: previewError,
  } = useMutation((id) => previewGrant(id, false), {
    onSuccess: async (res) => {
      if (!res) return;
      setFile(new File([res], 'name'));
    },
  });

  const {
    mutate: downloadPdf,
    isLoading: isDownloadingPdf,
    error: downloadPdfError,
  } = useMutation(downloadUserGrant, {
    onSuccess: async (res) => {
      if (!res) return;
      setFile(new File([res], 'name'));
    },
  });

  const { createMyGrant, updateMyGrant, isSaving } = useSaveMyGrant({
    onError: () => {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: `Failed saved your ${type}`,
      });
    },
    onCreateSuccess: (res) => {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: `Successfully saved your ${type}`,
      });
      const url =
        type === GRANTS_TYPES.GRANT
          ? `/my-grants/${res.id}`
          : `/my-letters/${res.id}`;
      reset(getDefaultValue(res));
      downloadPdf(res.id);
      setFile(undefined);
      navigate(url, { replace: true });
    },
    onUpdateSuccess: (res) => {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: `Successfully saved your ${type}`,
      });
      reset(getDefaultValue(res));
      downloadPdf(res.id);
      setFile(undefined);
      if (onUpdate) onUpdate(res);
    },
  });

  const isFetchingPreview = useMemo(
    () => isDownloadingPreview || isDownloadingPdf,
    [isDownloadingPreview, isDownloadingPdf]
  );
  const fetchingPreviewError = useMemo(
    () => previewError || downloadPdfError,
    [previewError, downloadPdfError]
  );

  const pages = selectedGrant?.pages?.map((page) =>
    replaceVariables(page.content, watchedFields)
  );

  const handleSelectedGrantChange = (value) => {
    setFile(null);
    setSelectedGrant(value);
  };

  const handlePrint = () => {
    const currentFields = getValues('fields');
    execPrint(selectedGrant, currentFields);
  };

  const handleSave = (values) => {
    const { fields: updatedFields, attachments: updateAttachments } = values;
    const changes = { fields: updatedFields, attachments: updateAttachments };
    if (userGrant) {
      updateMyGrant({
        id: userGrant.id,
        payload: changes,
      });
      return;
    }
    createMyGrant({ grantId: selectedGrant.id, ...changes });
  };

  const handleDownload = () => {
    downloadFile(
      file,
      `${underscored(selectedGrant.title)}_${new Date().getTime()}.pdf`
    );
  };

  const handleCancel = () => navigate(-1);

  useEffect(() => {
    setSelectedGrant(undefined);
    setFile(null);
  }, [type]);

  useEffect(() => {
    reset(getDefaultValue(selectedGrant));
    if (selectedGrant?.id) {
      preview(selectedGrant.id);
      return;
    }
    if (userGrant?.id) {
      downloadPdf(userGrant.id);
    }
  }, [selectedGrant]);

  useEffect(() => {
    if (userGrant) {
      setSelectedGrant(userGrant?.grant);
    }
  }, [userGrant]);

  useEffect(() => {
    if (grant) {
      setSelectedGrant(grant);
    }
  }, [grant]);

  usePrompt(() => setIsLeaveOpen(true), isLeaveConfirmed, isDirty);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(handleSave)}
        sx={{
          height: '100%',
          width: '100%',
          overflow: { xs: 'auto', md: 'hidden' },
          backgroundColor: BRAND_COLORS.offWhite,
          ...getScrollbarStyle('#c3c3c3'),
        }}
      >
        <Grid sx={{ height: '100%' }} container>
          <Grid
            xs={12}
            md={4}
            sx={{
              height: '100%',
              overflow: { xs: 'hidden', md: 'auto' },
              backgroundColor: BRAND_COLORS.offWhite,
              ...getScrollbarStyle('#c3c3c3'),
            }}
            item
          >
            <Box
              sx={{
                pl: 4.625,
                pr: 3.75,
                py: 6,
              }}
            >
              {userGrant ? (
                <Title2
                  fontFamily="Playfair Display"
                  ml={2}
                  mb={5.5}
                  color={BRAND_COLORS.darkGray}
                >
                  {userGrant?.grant?.title}
                </Title2>
              ) : (
                <GrantSelector
                  type={type}
                  onChange={handleSelectedGrantChange}
                  defaultGrant={grant}
                />
              )}
              {selectedGrant && (
                <GrantForm fields={fields} attachments={attachments} />
              )}
            </Box>
          </Grid>
          <Grid
            xs={12}
            md={8}
            item
            sx={{ height: '100%', overflow: 'auto', backgroundColor: '#fff' }}
          >
            <DocumentViewer
              pages={pages}
              type={type}
              file={file}
              isLoadingFile={isFetchingPreview}
              loadingFileError={fetchingPreviewError}
              onDownload={handleDownload}
              onPrint={handlePrint}
              onCancel={handleCancel}
              isSaving={isSaving}
              isFile={false}
            />
          </Grid>
        </Grid>
      </Box>
      {isLeaveOpen && (
        <ConfirmLeaveDialog
          open={isLeaveOpen}
          onClose={() => setIsLeaveOpen(false)}
          onSave={() => {
            handleSubmit(handleSave)();
            setIsLeaveOpen(false);
          }}
          onConfirm={() => {
            setIsLeaveConfirmed(true);
            setIsLeaveOpen(false);
          }}
        />
      )}
    </FormProvider>
  );
};

export default EditorPage;
