import { Box, styled } from '@mui/material';
import Flex from 'components/Flex';
import useValidateUserAccess from 'hooks/useValidateUserAccess';
import BgUnauthorized from 'images/bg-unauthorized.png';
import LogoWhite from 'images/logo-white.png';
import { Outlet } from 'react-router-dom';

const UnauthorizedBox = styled(({ selected, ...props }) => <Box {...props} />)(
  ({ theme }) => ({
    [theme.breakpoints.up('xxl')]: {
      height: 'unset',
    },
    [theme.breakpoints.down('xxl')]: {
      height: '100vh',
      margin: '50px 0',
    },
  })
);

const UnauthorizedLayout = () => {
  const { isInitialized } = useValidateUserAccess();
  if (!isInitialized) return null;
  return (
    <Flex
      flexDirection="column"
      center
      height="100vh"
      width="100vw"
      position="relative"
      overflow="auto"
      sx={{
        background: `linear-gradient(#0c50e1, rgb(34 51 84 / 70%)),url('${BgUnauthorized}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <UnauthorizedBox>
        <Box textAlign="center">
          <Box component="img" width={116} src={LogoWhite} alt="6FG" />
        </Box>
        <Outlet />
      </UnauthorizedBox>
    </Flex>
  );
};

export default UnauthorizedLayout;
