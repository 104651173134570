import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  lighten,
  styled,
  SvgIcon,
  TextField,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { saveOrganization } from 'api';
import Flex from 'components/Flex';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { Body, Title2 } from 'components/Text';
import { useAuth } from 'contexts/Auth';
import { ReactComponent as TooltipIcon } from 'images/icons/tooltip.svg';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ORGANIZATION_TYPES } from 'utils/constants';

const OrgTypeItem = styled(({ selected, ...props }) => <Box {...props} />)(
  ({ theme, selected }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
    border: `2px solid ${
      selected ? theme.palette.secondary.main : theme.palette.grey[200]
    }`,
    color: selected ? theme.palette.secondary.main : theme.palette.grey[800],
    padding: theme.spacing(2, 3.75),
    width: '100%',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      width: 212,
    },
    '&:hover, &:focus, &:active': {
      backgroundColor: selected
        ? lighten(theme.palette.secondary.main, 0.9)
        : theme.palette.grey[100],
    },
  })
);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 6px 16px',
    fontSize: 11,
  },
}));

const OrgTypeSelect = ({ selected, onChange }) => {
  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <Flex center justifyContent="space-between">
      <OrgTypeItem
        selected={selected === ORGANIZATION_TYPES.NON_PROFIT}
        onClick={() => handleChange(ORGANIZATION_TYPES.NON_PROFIT)}
      >
        <Body bold my={0}>
          Non-profit
        </Body>
        {selected === ORGANIZATION_TYPES.NON_PROFIT ? (
          <CheckBoxIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </OrgTypeItem>
      <OrgTypeItem
        selected={selected === ORGANIZATION_TYPES.FOR_PROFIT}
        onClick={() => handleChange(ORGANIZATION_TYPES.FOR_PROFIT)}
      >
        <Body bold my={0}>
          Profit
        </Body>
        {selected === ORGANIZATION_TYPES.FOR_PROFIT ? (
          <CheckBoxIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </OrgTypeItem>
    </Flex>
  );
};

const OrgSetup = () => {
  const { showSnackbar } = useSnackbar();
  const { user, refreshUser } = useAuth();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      mission: '',
      programDesign: '',
      expectedResults: '',
      organizationType: ORGANIZATION_TYPES.NON_PROFIT,
    },
  });

  const { mutate: saveOrgInfo, isLoading } = useMutation(
    (data) => saveOrganization(user.id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        refreshUser('/');
      },
      onError: (err) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: err?.message || 'Failed to save your organization info',
        });
      },
    }
  );

  const onSubmit = async (payload) => {
    saveOrgInfo(payload);
  };

  return (
    <Box component={Card} width={{ xs: '100%', md: 550 }}>
      <Box component={CardContent} px={6.875} py={7.375}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Title2 fontFamily="Playfair Display" mt={0} mb={3}>
            Organization Information
          </Title2>
          <Body color="ui56" mt={0} mb={5}>
            The following information will help us …blandit accumsan. Ut vel
            dictum sem, a pretium dui. In malesuada enim in dolor euismod.
          </Body>
          <Box mb={{ xs: 3, md: 5 }}>
            <Controller
              control={control}
              name="organizationType"
              render={({ field }) => (
                <OrgTypeSelect
                  selected={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              What's your organizations's name?
              <BootstrapTooltip title="Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>

            <Controller
              control={control}
              rules={{
                required: { value: true, message: 'Name is required' },
              }}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Answer here"
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  labelProps={{
                    my: 0,
                    color: 'ui',
                  }}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              What's your mission statement?
              <BootstrapTooltip title="Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Mission statement is required',
                },
              }}
              name="mission"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Answer here"
                  multiline
                  rows={4}
                  error={!!errors?.mission}
                  helperText={errors?.mission?.message}
                  labelProps={{
                    my: 0,
                    color: 'ui',
                  }}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Body mt={0} mb={1.75} bold>
              Program Design
              <BootstrapTooltip title="Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Prgoram design is required',
                },
              }}
              name="programDesign"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Answer here"
                  error={!!errors?.programDesign}
                  helperText={errors?.programDesign?.message}
                  labelProps={{
                    my: 0,
                    color: 'ui',
                  }}
                />
              )}
            />
          </Box>
          <Box mb={5}>
            <Body mt={0} mb={1.75} bold>
              Expected results/outcomes
              <BootstrapTooltip title="Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                <SvgIcon
                  sx={{
                    height: 27,
                    verticalAlign: 'middle',
                    marginLeft: '10px',
                  }}
                >
                  <TooltipIcon />
                </SvgIcon>
              </BootstrapTooltip>
            </Body>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Expected results/outcomes is required',
                },
              }}
              name="expectedResults"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Answer here"
                  multiline
                  rows={4}
                  error={!!errors?.expectedResults}
                  helperText={errors?.expectedResults?.message}
                  labelProps={{
                    my: 0,
                    color: 'ui',
                  }}
                />
              )}
            />
          </Box>
          <Box mb={5}>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              size="large"
              sx={{ backgroundColor: 'background.button.dark' }}
            >
              Done
              {isLoading && (
                <Box
                  component={CircularProgress}
                  ml={2}
                  size={16}
                  color="inherit"
                />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default OrgSetup;
