import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  styled,
  Box,
  Collapse,
  Drawer as MuiDrawer,
  useTheme,
  Divider,
  Avatar,
  Button,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Flex from 'components/Flex';
import { Small, Body } from 'components/Text';
import Link from 'components/Link';
import { useAuth } from 'contexts/Auth';
import { useLayout } from 'contexts/Layout';
import { NAV_LINKS, DRAWER_WIDTH } from 'utils/constants';
import Logo from '../Logo';

const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const OrganizationInfo = ({ organization, ...props }) => (
  <Box {...props}>
    <Box mb={4.75}>
      <Small mt={0} mb={1} color="grey.600">
        Organization Information
      </Small>
      <Body my={0} color="grey.800">
        {organization.name}
      </Body>
    </Box>
    <Box mb={4.75}>
      <Small mt={0} mb={1} color="grey.600">
        Program Design
      </Small>
      <Body my={0} color="grey.800">
        {organization.programDesign}
      </Body>
    </Box>
    <Box mb={4.75}>
      <Small mt={0} mb={1} color="grey.600">
        Mission Statement
      </Small>
      <Body my={0} color="grey.800">
        {organization.mission}
      </Body>
    </Box>
  </Box>
);

const MenuItemWrapper = styled(({ active, ...props }) => <Box {...props} />)(
  ({ theme, active }) => ({
    padding: theme.spacing(2.5),
    backgroundColor: active ? theme.palette.primary.main : 'transparent',
    color: active ? theme.palette.common.white : theme.palette.text.primary,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
  })
);

const MenuItem = ({ route, onClick }) => {
  const { adminOpen, toggleAdmin } = useLayout();
  const navigate = useNavigate();
  const location = useLocation();
  const isNested = useMemo(() => route?.children?.length > 0, [route]);
  const active = useMemo(() => {
    if (isNested) return false;
    return location?.pathname === route.href;
  }, [isNested, location, route]);

  const handleClick = (isParent = false) => {
    if (isParent) {
      toggleAdmin();
      return;
    }
    if (onClick) onClick();
    navigate(route.href);
  };

  return (
    <>
      {isNested ? (
        <>
          <MenuItemWrapper onClick={() => handleClick(true)}>
            <Body my={0}>{route.label}</Body>
            {adminOpen ? <ExpandLess /> : <ExpandMore />}
          </MenuItemWrapper>
          <Collapse in={adminOpen} timeout="auto" unmountOnExit>
            <Box pl={3}>
              {route.children.map((r, i) => (
                <MenuItem
                  key={i}
                  route={r}
                  listItemProps={{
                    sx: { pl: 4 },
                  }}
                  onClick={onClick}
                />
              ))}
            </Box>
          </Collapse>
        </>
      ) : (
        <MenuItemWrapper active={active} onClick={() => handleClick()}>
          <Box width={22} height={22} mr={2.5}>
            {route?.icon}
          </Box>
          <Body my={0} bold>
            {route.label}
          </Body>
        </MenuItemWrapper>
      )}
    </>
  );
};

const Drawer = ({ open, onToggleSidebar }) => {
  const theme = useTheme();
  const { user, fullName, signOut } = useAuth();

  const isAdmin = useMemo(() => user?.roles?.includes('curator'), [user]);
  const links = useMemo(() => {
    if (isAdmin) return NAV_LINKS;
    return NAV_LINKS.filter((link) => !link?.isAdmin);
  }, [isAdmin]);

  return (
    <MuiDrawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
        borderRadius: 0,
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Box px={4.25}>
        <Flex px={2.5} mt={3.75} mb={7.5} justifyContent="space-between">
          <Logo />
          <DrawerHeader>
            <IconButton onClick={onToggleSidebar}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
        </Flex>
        <Box px={2.5}>
          <Small my={0} color="grey.600">
            Grant Tools
          </Small>
        </Box>
        <Box my={4}>
          {links.map((route) => (
            <MenuItem key={route.label} route={route} />
          ))}
        </Box>
        <Divider />
        <Box px={2.5}>
          {user?.organizations?.map((org, i) => (
            <OrganizationInfo organization={org} my={4} key={i} />
          ))}
        </Box>
        <Divider />
        <Box my={4}>
          <Flex alignItems="center" mb={3}>
            <Box mr={2.75}>
              <Avatar variant="rounded" alt={fullName} />
            </Box>
            <Box>
              <Body my={0} color="grey.800">
                {fullName}
              </Body>
              <Link href="/account" underline="none">
                <Small my={0} color="grey.600">
                  Manage Profile
                </Small>
              </Link>
            </Box>
          </Flex>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'background.button.dark' }}
            onClick={signOut}
            fullWidth
          >
            Logout
          </Button>
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
