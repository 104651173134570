/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { createUserGrant, updateUserGrant, uploadFile } from 'api';
import { useAuth } from 'contexts/Auth';
import { GRANTS_FIELD_TYPES } from 'utils/constants';

function getFileUrl(file, user) {
  const filename = `${user?.id}_${Date.now()}`;
  return uploadFile(file, 'user-grants', filename);
}

async function prepRequest(payload, user) {
  const { attachments = [], fields } = payload;

  // upload attachments first then use url
  const modifiedAttachments = [];
  for (const attachment of attachments) {
    if (typeof attachment.value === 'object') {
      const url = await getFileUrl(attachment.value, user);
      modifiedAttachments.push({ ...attachment, value: url });
      continue;
    }
    modifiedAttachments.push(attachment);
  }

  // upload fields of type file first then use url
  const modifiedFields = [];
  for (const field of fields) {
    if (
      field.type === GRANTS_FIELD_TYPES.FILE &&
      typeof field.value === 'object'
    ) {
      const url = await getFileUrl(field.value, user);
      modifiedFields.push({ ...field, value: url });
      continue;
    }
    modifiedFields.push(field);
  }
  return {
    ...payload,
    attachments: modifiedAttachments,
    fields: modifiedFields,
  };
}

export function useSaveMyGrant({ onCreateSuccess, onUpdateSuccess, onError }) {
  const { user } = useAuth();
  const {
    mutate: createMyGrant,
    isLoading: isCreating,
    error: errorCreating,
  } = useMutation(
    async (payload) => {
      const body = await prepRequest(payload, user);
      return createUserGrant(body);
    },
    {
      onError,
      onSuccess: async (res) => {
        if (!res) return;
        if (onCreateSuccess) onCreateSuccess(res);
      },
    }
  );
  const {
    mutate: updateMyGrant,
    isLoading: isUpdating,
    error: errorUpdating,
  } = useMutation(
    async ({ id, payload }) => {
      const body = await prepRequest(payload, user);
      return updateUserGrant(id, body);
    },
    {
      onError,
      onSuccess: async (res) => {
        if (!res) return;
        if (onUpdateSuccess) onUpdateSuccess(res);
      },
    }
  );

  const isSaving = useMemo(
    () => isCreating || isUpdating,
    [isCreating, isUpdating]
  );

  const error = useMemo(
    () => errorCreating || errorUpdating,
    [errorCreating, errorUpdating]
  );

  return { createMyGrant, updateMyGrant, isSaving, error };
}
