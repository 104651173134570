import React from 'react';
import { Box, Dialog as MuiDialog, Slide, styled } from '@mui/material';

const bottomSheetMobileStyles = {
  borderRadius: '16px 16px 0 0',
  minWidth: '100%',
  maxWidth: '100%',
  position: 'fixed',
  bottom: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
};

const Dialog = styled(
  ({
    isBottomSheet: _,
    fullScreen: _f,
    maxWidth: _ma,
    minWidth: _mi,
    overriddenProps,
    ...props
  }) => <MuiDialog {...props} {...overriddenProps} />
)(({ theme, isBottomSheet, fullScreen, maxWidth = 374, minWidth = 374 }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: theme.palette.bg,
    ...(isBottomSheet
      ? {
          [theme.breakpoints.down('sm')]: bottomSheetMobileStyles,
          borderRadius: 16,
          minWidth,
          maxWidth,
          position: 'relative',
          bottom: 'auto',
        }
      : {}),
    ...(fullScreen
      ? {
          height: '100vh',
          width: '100vw',
          maxWidth: '100vw',
          maxHeight: '100vh',
          margin: 0,
          borderRadius: 0,
          [theme.breakpoints.down('sm')]: {
            ...bottomSheetMobileStyles,
            borderRadius: 0,
          },
        }
      : {}),
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function Modal({
  children,
  boxProps,
  isBottomSheet = true,
  ...props
}) {
  const overriddenBoxProps = {
    py: 3,
    px: 3,
    ...boxProps,
  };
  return (
    <Dialog
      TransitionComponent={Transition}
      isBottomSheet={isBottomSheet}
      {...props}
    >
      <Box overflow="auto" {...overriddenBoxProps}>
        {children}
      </Box>
    </Dialog>
  );
}
