import { styled } from '@mui/material';
import { DRAWER_WIDTH, FAQ_WIDTH } from 'utils/constants';

const MainContent = styled('main')(({ theme, drawerOpen, faqOpen }) => ({
  display: 'flex',
  height: '100vh',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(drawerOpen
    ? {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }
    : {
        marginLeft: `-${DRAWER_WIDTH}px`,
      }),
  ...(faqOpen
    ? {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }
    : {
        marginRight: `-${FAQ_WIDTH - 60}px`,
      }),
}));

export default MainContent;
