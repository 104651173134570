import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, TextField } from '@mui/material';

const AttachmentItem = ({ item, onChange, onRemove, ...props }) => {
  const handleLabelChange = (e) => {
    if (!onChange) return;
    const { value } = e.target;
    onChange({ label: value });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', ...props }}>
      <TextField
        value={item?.label || ''}
        onChange={handleLabelChange}
        sx={{ mr: 2 }}
        fullWidth
      />

      <IconButton onClick={onRemove}>
        <DeleteOutlineIcon color="error" />
      </IconButton>
    </Box>
  );
};

export default AttachmentItem;
