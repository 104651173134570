import { QueryClientProvider } from 'react-query';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { theme } from 'theme';
import { AuthContextProvider } from 'contexts/Auth';
import { LayoutContextProvider } from 'contexts/Layout';
import { SnackbarProvider } from 'components/Snackbar';
import AppRoutes from 'AppRoutes';
import queryClient from 'queryClient';

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <LayoutContextProvider>
          <AuthContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AppRoutes />
            </LocalizationProvider>
          </AuthContextProvider>
        </LayoutContextProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  </ThemeProvider>
);

export default App;
