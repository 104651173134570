import { Card, CardContent, Box } from '@mui/material';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import Text from 'components/Text';

const Error = ({
  icon,
  title = 'Unhandled Error',
  description = 'Something went wrong',
  ...props
}) => {
  const ErrorIcon = icon ?? ErrorTwoToneIcon;
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
      py={3}
      {...props}
    >
      <Card sx={{ width: 575, height: 300 }}>
        <CardContent component={Box} height="100%">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <ErrorIcon sx={{ fontSize: 60 }} color="error" />
              <Text color="primaryAlt" ml={1}>
                {title}
              </Text>
            </Box>
            <Text variant="body1" color="primaryAlt" textAlign="center">
              {description}
            </Text>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default Error;
