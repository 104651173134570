export function openPrintWindow({ title, content }) {
  const template = `
  <!DOCTYPE html>
    <html>
        <head><title>${title}</title></head>
        <body>${content}</body>
    </html>
    `;
  const printWin = window.open('', '');
  printWin.document.open();
  printWin.document.write(template);
  printWin.document.close();
  printWin.focus();
  printWin.print();
  printWin.close();
}

export function downloadFile(file, name) {
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
}

export function removeEmptyValuesFromArr(arr) {
  if (!arr) return [];
  return arr.map((i) => i?.trim()).filter((t) => t !== '' && t !== undefined);
}

export function removeEmptyValuesFromObj(obj) {
  if (!obj) return {};
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => {
      if (v === false) return true;
      return !!v;
    })
  );
}

export function replaceVariables(content, fields = []) {
  let body = content;
  fields.forEach((field) => {
    const reg = new RegExp(`\\{{${field.name}\\}}`, 'g');
    body = body.replace(reg, field.value ?? `[${field.name}]`);
  });
  return body;
}
