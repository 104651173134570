import callApi from 'api/callApi';

const uploadFile = async (file, directory, fileName) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('directory', directory);
  formData.append('fileName', fileName);
  return callApi('/media/upload', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    needsAuthToken: true,
  });
};

// eslint-disable-next-line import/prefer-default-export
export { uploadFile };
