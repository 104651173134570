import { useParams } from 'react-router-dom';
import { useSnackbar, SNACKBAR_VARIANTS } from 'components/Snackbar';
import PageHeader from 'components/PageHeader';
import Spinner from 'components/Spinner';
import Error from 'components/Error';
import { useMutation, useQuery } from 'react-query';
import {
  getGrantByIdQuery,
  previewGrant,
  updateGrant,
} from 'api/services/grants';
import GrantForm from './Form';
import { checkIfFieldsOnPages, prepRequest } from './helper';

const EditGrantPage = () => {
  const { id } = useParams();
  const { showSnackbar } = useSnackbar();
  const { data: grant, isLoading, error } = useQuery(getGrantByIdQuery(id));

  const { mutate: saveGrant, isLoading: isSaving } = useMutation(
    (data) => updateGrant(id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        showSnackbar({
          variant: SNACKBAR_VARIANTS.SUCCESS,
          message: 'Successfully saved your grant',
        });
      },
      onError: (err) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: err?.message || 'Failed to save grant',
        });
      },
    }
  );

  const { mutate: preview, isLoading: isDownloadingPreview } = useMutation(
    () => previewGrant(id),
    {
      onSuccess: (data) => {
        if (!data) return;
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${id}_preview.pdf`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      },
      onError: (err) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: err?.message || 'Failed to preview file',
        });
      },
    }
  );

  const handleSubmit = async (formData) => {
    const isFieldsValid = checkIfFieldsOnPages(formData);
    if (!isFieldsValid) {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: 'Please fill all required fields',
      });
      return;
    }
    const payload = await prepRequest(formData);
    saveGrant(payload);
  };

  return (
    <>
      {isLoading && <Spinner />}
      {error && <Error description={error?.message} />}
      {!isLoading && grant && (
        <>
          <PageHeader title={grant.title} hideAction />
          <GrantForm
            grant={grant}
            onSubmit={handleSubmit}
            isSaving={isSaving}
            onPreview={preview}
            isDownloadingPreview={isDownloadingPreview}
          />
        </>
      )}
    </>
  );
};

export default EditGrantPage;
