import { useMemo, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyEmail } from 'api';
import Error from 'components/Error';
import { Box, Card } from '@mui/material';
import { Body, Title2 } from 'components/Text';
import { useSnackbar, SNACKBAR_VARIANTS } from 'components/Snackbar';

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showSnackbar } = useSnackbar();

  const code = useMemo(() => searchParams.get('code'), [searchParams]);

  const { mutate, isLoading, error } = useMutation(verifyEmail, {
    onSuccess: (data) => {
      if (!data) return;
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: 'Your account has been successfully verified',
      });
      navigate('/');
    },
  });

  useEffect(() => {
    if (!code) return;
    mutate(code);
  }, [code]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      position="relative"
    >
      <Box
        maxWidth={{ xs: 'initial', md: 568 }}
        minWidth={{ xs: 'initial', md: 568 }}
        width={{ xs: '90%', md: '100%' }}
        mx="auto"
        mt={{ xs: 4, md: 5.625 }}
        mb={{ xs: 6.25, md: 12.5 }}
      >
        <Card>
          <Box px={{ xs: 3.75, md: 7 }} my={5.625} width="100%">
            <Box mt={0} mb={2}>
              <Title2 mt={0} mb={2}>
                Verifying your account
              </Title2>
            </Box>
            <Box mb={3}>
              {(isLoading || !error) && (
                <Body color="grey.800" my={0}>
                  Please wait while we verify your account...
                </Body>
              )}
              {error && <Error title={error} />}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default VerifyEmailPage;
