import {
  Container,
  Card,
  CardContent,
  Box,
  TextField,
  CardActions,
  Button,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useAuth } from 'contexts/Auth';
import PageHeader from 'components/PageHeader';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import { changePassword } from 'api';
import { VALIDATORS } from 'utils/constants/validators';

const DEFAULT = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePasswordPage = () => {
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const {
    formState: { errors },
    register,
    handleSubmit,
    getValues,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  });

  const { mutate, isLoading } = useMutation(
    (data) => changePassword(user.id, data),
    {
      onSuccess: (data) => {
        if (!data) return;
        reset(DEFAULT);
        showSnackbar({
          variant: SNACKBAR_VARIANTS.SUCCESS,
          message: 'Your password has been successfully updated',
        });
      },
      onError: (error) => {
        showSnackbar({
          variant: SNACKBAR_VARIANTS.ERROR,
          message: error?.message || 'Failed to update your password',
        });
      },
    }
  );

  const onSubmit = (data) => {
    const { oldPassword, newPassword } = data;
    mutate({ oldPassword, newPassword });
  };

  const fullName = `${user?.firstName} ${user?.lastName}`;

  return (
    <Container>
      <PageHeader title={`Change Password for ${fullName}`} hideAction />
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ maxWidth: 375 }}>
          <CardContent>
            <Box
              sx={{
                '& .MuiTextField-root': { m: 1 },
              }}
              noValidate
              autoComplete="off"
              display="flex"
              flexDirection="column"
            >
              <TextField
                label="Password"
                name="oldPassword"
                type="password"
                error={!!errors?.oldPassword}
                helperText={errors?.oldPassword?.message}
                {...register('oldPassword', {
                  required: {
                    value: true,
                    message: 'Old Password is required',
                  },
                })}
              />
              <TextField
                label="Password"
                name="newPassword"
                type="password"
                error={!!errors?.newPassword}
                helperText={errors?.newPassword?.message}
                {...register('newPassword', VALIDATORS.password)}
              />
              <TextField
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                error={!!errors?.confirmPassword}
                helperText={errors?.confirmPassword?.message}
                {...register('confirmPassword', {
                  required: {
                    value: true,
                    message: 'Confirm Password is required',
                  },
                  validate: (value) =>
                    getValues('newPassword') === value ||
                    "Passwords don't match",
                })}
              />
            </Box>
          </CardContent>
          <CardActions>
            <Box display="flex" justifyContent="end" width="100%" m={2}>
              <Button type="submit" variant="contained" disabled={isLoading}>
                Change password
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default ChangePasswordPage;
