import { Box, Button } from '@mui/material';
import Modal from 'components/Modal';
import { Body, Title2 } from 'components/Text';

export default function DeleteDialog({ open, onClose, onConfirm, type }) {
  return (
    <Modal maxWidth={538} open={open} onClose={onClose}>
      <Box mb={3}>
        <Title2 mt={0} mb={3} fontFamily="Playfair Display">
          Delete
        </Title2>
        <Body my={0}>Are you sure you want to remove this {type}?</Body>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Box
          component={Button}
          variant="contained"
          px={4.5}
          py={2}
          sx={{ backgroundColor: 'background.button.dark' }}
          onClick={onConfirm}
          mr={2}
        >
          Yes, I'm sure
        </Box>
        <Button variant="text" onClick={onClose}>
          No, go back
        </Button>
      </Box>
    </Modal>
  );
}
