import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  CircularProgress,
  Card,
  Button,
  TextField,
  CardContent,
} from '@mui/material';
import { Title2 } from 'components/Text';
import { VALIDATORS } from 'utils/constants';
import {
  LocationField,
  LocationSearchField,
  DEFAULT_LOCATION,
} from 'components/LocationField';

const UserForm = ({ user, onSubmit, isLoading }) => {
  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      company: user?.company || '',
      phone: user?.phone || '',
      email: user?.email || '',
      address: user?.address || DEFAULT_LOCATION,
    },
  });

  return (
    <Box display="flex" justifyContent="center">
      <Box component={Card} width={{ xs: '100%', md: 568 }}>
        <CardContent>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Title2 fontFamily="Playfair Display" mt={0} mb={3} color="ui">
              Basic Info
            </Title2>
            <Box mb={6}>
              <Box mb={2}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      placeholder="youremail@domain.com"
                      label="Email"
                      disabled
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                      labelProps={{
                        my: 0,
                        color: 'ui',
                      }}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'First name is required',
                    },
                  }}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="First name"
                      placeholder="John"
                      error={!!errors?.firstName}
                      helperText={errors?.firstName?.message}
                      labelProps={{
                        my: 0,
                        color: 'ui',
                      }}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  control={control}
                  rules={{
                    required: { value: true, message: 'Last name is required' },
                  }}
                  name="lastName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      placeholder="Doe"
                      label="Last name"
                      error={!!errors?.lastName}
                      helperText={errors?.lastName?.message}
                      labelProps={{
                        my: 0,
                        color: 'ui',
                      }}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  control={control}
                  rules={VALIDATORS.phone}
                  name="phone"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Phone"
                      error={!!errors?.phone}
                      helperText={errors?.phone?.message}
                      labelProps={{
                        my: 0,
                        color: 'ui',
                      }}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  control={control}
                  name="company"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Company"
                      error={!!errors?.company}
                      helperText={errors?.company?.message}
                      labelProps={{
                        my: 0,
                        color: 'ui',
                      }}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <LocationField>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <LocationSearchField
                        onMapSelect={(location) => {
                          field.onChange(location);
                        }}
                        location={field.value}
                      />
                    )}
                  />
                </LocationField>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading}
                size="large"
                sx={{ px: 6 }}
              >
                Save changes
                {isLoading && (
                  <Box
                    component={CircularProgress}
                    ml={2}
                    size={16}
                    color="inherit"
                  />
                )}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Box>
  );
};
export default UserForm;
