import { styled, Box, darken, Button } from '@mui/material';
import Flex from 'components/Flex';
import { Body, Title2, Title3 } from 'components/Text';
import Bg4 from 'images/bg-4.png';
import { useNavigate } from 'react-router-dom';

const BannerOverlay = styled(Box)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, rgba(0, 0, 0, 0) 40%), url(${Bg4})`,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 185,
  borderRadius: theme.spacing(4),
}));

const WriteLetterButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  '&:hover, &:focus, &:active': {
    backgroundColor: darken(theme.palette.common.white, 0.05),
  },
}));

const WriteGrantButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.black,
  '&:hover, &:focus, &:active': {
    backgroundColor: darken(theme.palette.secondary.main, 0.05),
  },
}));

const DashboardBanner = (props) => {
  const navigate = useNavigate();

  const handleWriteLetter = () => {
    navigate('/my-letters');
  };

  const handleWriteGrant = () => {
    navigate('/my-grants');
  };

  return (
    <Box
      width="100%"
      height={{ xs: 'auto', md: 446 }}
      position="relative"
      bgcolor="primary.main"
      borderRadius={5}
      boxShadow={'rgb(0 0 0 / 24%) 0px 6px 16px'}
      {...props}
    >
      <BannerOverlay />
      <Flex
        position="relative"
        px={{ xs: 4, md: 7.25 }}
        py={{ xs: 4, md: 7.25 }}
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box maxWidth={371} mb={{ xs: 5, md: 0 }}>
          <Title3 color="common.white" my={0}>
            Grant Suite
          </Title3>
          <Title2
            mt={0}
            mb={3}
            color="common.white"
            fontFamily="Playfair Display"
          >
            Dashboard
          </Title2>
          <Body my={0} color="common.white">
            Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec
            viverra eleifend lacus, vitae ullamcorper metus. Sed sollicitu.
          </Body>
        </Box>
        <Box>
          <Body mt={0} mb={3} color="common.white" fontWeight={700}>
            Start Now!
          </Body>
          <Flex alignItems="center" flexDirection={{ xs: 'column', md: 'row' }}>
            <Box mr={{ xs: 0, md: 2.625 }} mb={{ xs: 2, md: 0 }}>
              <WriteLetterButton
                variant="contained"
                onClick={handleWriteLetter}
              >
                Write a letter
              </WriteLetterButton>
            </Box>
            <WriteGrantButton variant="contained" onClick={handleWriteGrant}>
              Write a grant
            </WriteGrantButton>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardBanner;
