import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { DiscussionEmbed } from 'disqus-react';
import { Box, Card, Container, Paper, Chip, Button } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getGrantByIdQuery, previewGrant } from 'api';
import { GRANTS_TYPES, SITE_URL } from 'utils/constants';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';
import PdfViewer from 'components/PdfViewer';
import Text, { Body, Large } from 'components/Text';
import Flex from 'components/Flex';
import Link from 'components/Link';
import GrantDetailsSkeleton from './skeletons/DetailsSkeleton';

const GrantDetailsPage = () => {
  const { id } = useParams();
  const { showSnackbar } = useSnackbar();
  const [file, setFile] = useState();

  const {
    mutate: preview,
    isLoading: isDownloadingPreview,
    error: previewError,
  } = useMutation(() => previewGrant(id), {
    onSuccess: async (data) => {
      if (!data) return;
      setFile(new File([data], 'name'));
    },
  });
  const {
    data: grant,
    isLoading: isGrantLoading,
    error: grantError,
  } = useQuery(getGrantByIdQuery(id));

  const isLoading = useMemo(
    () => isGrantLoading || isDownloadingPreview,
    [isGrantLoading, isDownloadingPreview]
  );
  const error = useMemo(
    () => grantError || previewError,
    [grantError, previewError]
  );

  useEffect(() => {
    if (!id) return;
    preview();
  }, [id]);

  useEffect(() => {
    if (!error) return;
    showSnackbar({
      variant: SNACKBAR_VARIANTS.ERROR,
      message:
        typeof error === 'string'
          ? error
          : error?.message ?? 'Something went wrong. Please try again.',
    });
  }, [error]);

  return (
    <>
      <Container>
        {isLoading && <GrantDetailsSkeleton />}
        {!isLoading && !!grant && (
          <>
            <Card sx={{ height: '100%', px: 2, py: 3, mt: 4 }}>
              <Text>{grant.title}</Text>
              {grant.description && (
                <Body color="primary.lighter">{grant.description}</Body>
              )}
              {grant?.location?.name && (
                <Flex alignItems="center" sx={{ mt: 2 }}>
                  <LocationOnIcon color="primary" />
                  <Text variant="body2" m={0} color="black">
                    {grant.location.name}
                  </Text>
                </Flex>
              )}
              {!!grant?.value && (
                <Chip
                  color="primary"
                  size="small"
                  variant="outlined"
                  icon={<PaidIcon />}
                  label={grant.value}
                  sx={{ mt: 2 }}
                />
              )}
              {!!grant.attachments?.length && (
                <Box>
                  <Body color="primary.lighter" mb={1} bold>
                    Required attachments:
                  </Body>
                  {grant.attachments.map((t, idx) => (
                    <Body key={`attachment-${idx}`} my={0} mb={1}>
                      {t.label}
                    </Body>
                  ))}
                </Box>
              )}
              {grant.type !== GRANTS_TYPES.LINK && (
                <Flex justifyContent="end">
                  <Link href={`/grants/${grant.id}/apply`} variant="contained">
                    <Button size="small" variant="contained">
                      Apply
                    </Button>
                  </Link>
                </Flex>
              )}
            </Card>
          </>
        )}
      </Container>
      {!isLoading && file && (
        <Box sx={{ backgroundColor: 'grey.200', my: 5, py: 3 }}>
          <Flex flexDirection="column" center>
            <Large color="primary.lighter" m={0} mb={1} bold>
              Preview
            </Large>
            <Box width={500}>
              <Paper
                sx={{
                  borderRadius: 0,
                }}
              >
                <PdfViewer file={file} />
              </Paper>
            </Box>
          </Flex>
        </Box>
      )}
      <Container>
        {grant?.id && (
          <DiscussionEmbed
            shortname="grants-app"
            config={{
              url: `${SITE_URL}/grants/${grant.id}`,
              identifier: `grants-${grant.id}`,
              title: grant.title,
            }}
          />
        )}
      </Container>
    </>
  );
};
export default GrantDetailsPage;
