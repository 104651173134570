import qs from 'qs';
import callApi from 'api/callApi';

const KEYS = {
  all: (filter) => ['userGrants', filter],
  byId: (id) => ['userGrantById', id],
};

export const getUserGrants = async (filters) => {
  const query = qs.stringify(filters);
  return callApi(`/user-grants?${query}`, {
    method: 'GET',
  });
};

export const getUserGrantsById = async ({ queryKey }) => {
  try {
    const [, id] = queryKey;
    const data = await callApi(`/user-grants/${id}`, {
      method: 'GET',
      needsAuthToken: true,
    });
    return data;
  } catch (_) {
    throw new Error('Failed to load grant application');
  }
};

export const createUserGrant = async (data) =>
  callApi(`/user-grants`, {
    method: 'POST',
    data,
    needsAuthToken: true,
  });

export const deleteUserGrant = async (id) =>
  callApi(`/user-grants/${id}`, {
    method: 'DELETE',
    needsAuthToken: true,
  });

export const updateUserGrant = async (id, data) =>
  callApi(`/user-grants/${id}`, {
    method: 'PATCH',
    data,
    needsAuthToken: true,
  });

export const downloadUserGrant = async (id) =>
  callApi(`/user-grants/${id}/downloadPdf`, {
    method: 'GET',
    needsAuthToken: true,
    responseType: 'blob',
  });

export const getUserGrantsQuery = (filter) => ({
  queryKey: KEYS.all(filter),
  queryFn: ({ queryKey }) => getUserGrants(queryKey[1]),
  enabled: !!filter,
});

export const getUserGrantByIdQuery = (id) => ({
  queryKey: KEYS.byId(id),
  queryFn: getUserGrantsById,
  enabled: !!id,
});
