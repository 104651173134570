import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getGrantByIdQuery } from 'api';
import EditorPage from './Editor';

const NewGrantPage = ({ type }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('grantId');
  const { data: grant, isLoading } = useQuery(getGrantByIdQuery(id));
  return (
    <>
      {id && (
        <>
          {isLoading ? 'Loading...' : <EditorPage type={type} grant={grant} />}
        </>
      )}
      {!id && <EditorPage type={type} />}
    </>
  );
};

export default NewGrantPage;
