import { Box, CircularProgress } from '@mui/material';
import { getUserGrantByIdQuery } from 'api';
import Flex from 'components/Flex';
import { Large, Title3 } from 'components/Text';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import EditorPage from './Editor';

const EditGrantPage = ({ type }) => {
  const { id } = useParams();
  const {
    data: userGrant,
    isLoading,
    refetch,
    error,
  } = useQuery(getUserGrantByIdQuery(id));
  const data = useMemo(() => {
    if (!userGrant) return null;
    return {
      ...userGrant,
      grant: {
        ...userGrant?.grant,
        fields: userGrant.fields,
        attachments: userGrant.attachments,
      },
    };
  }, [userGrant]);

  const handleOnUpdate = () => {
    refetch();
  };

  if (isLoading) {
    return (
      <Flex width="100%" height="100%" center>
        <CircularProgress size={48} />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex width="100%" height="100%" center>
        <Box maxWidth={400}>
          <Title3 mt={0} mb={2} fontFamily="Playfair Display">
            {error?.message || 'Failed to load grant application'}
          </Title3>
          <Large my={0}>
            Something went wrong while loading the grant application. Please try
            again or click <Link to="/my-grants">here</Link> to go back to view
            other applications.
          </Large>
        </Box>
      </Flex>
    );
  }

  return <EditorPage type={type} userGrant={data} onUpdate={handleOnUpdate} />;
};

export default EditGrantPage;
