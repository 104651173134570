import {
  Box,
  Card,
  CardContent,
  Grid,
  Link as MuiLink,
  styled,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { darken, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Flex from 'components/Flex';
import Link from 'components/Link';
import Text, { Large } from 'components/Text';
import moment from 'moment';
import React from 'react';
import { GRANTS_TYPES } from 'utils/constants';

const Description = styled(Text)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box !important',
  '-webkit-line-clamp': '3 !important',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
});

const CardOverlay = styled(Box)({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: 16,
});

const CardButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontWeight: 500,
}));

const OverlayButton = styled(CardButton)(({ theme }) => ({
  width: '75%',
  maxWidth: '250px',
  margin: theme.spacing(0.5),
}));

const UseButton = styled(OverlayButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.button.dark,
  color: theme.palette.common.white,
  '&:hover, &:focus, &:active': {
    backgroundColor: darken(theme.palette.common.black, 0.05),
  },
  '&[disabled]': {
    color: theme.palette.common.white,
  },
}));

const ViewDetailButton = styled(OverlayButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  '&:hover, &:focus, &:active': {
    backgroundColor: 'transparent',
  },
}));

const VisitButton = styled(OverlayButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  '&:hover, &:focus, &:active': {
    backgroundColor: darken(theme.palette.common.white, 0.05),
  },
  '&[disabled]': {
    color: theme.palette.common.black,
  },
}));

const getLink = (grant) => (grant.type === GRANTS_TYPES.LINK ? grant.url : '');

const TemplateCard = ({ grant, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box
        sx={props}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        position="relative"
      >
        <Box
          component={Card}
          height={{ xs: '100%', md: 344 }}
          sx={{ px: 2, py: 3, cursor: 'pointer' }}
        >
          <Box component={CardContent} height="100%">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box>
                <Large fontWeight={700}>{grant?.title}</Large>
                {grant?.description && (
                  <Description variant="body1" color="black" sx={{ mt: 2 }}>
                    {grant?.description}
                  </Description>
                )}
              </Box>
              <Box>
                <Text
                  variant="body2"
                  color="black"
                  display={'block'}
                  marginTop={3}
                >
                  Posted Date:
                </Text>
                <Text variant="body2" color="black" fontWeight={700}>
                  {moment(grant.createdDate).format('MMM DD, YYYY')}
                </Text>
                <Text
                  variant="body2"
                  color="black"
                  display={'block'}
                  marginTop={3}
                >
                  Estimated Total Program Funding:
                </Text>
                <Text variant="body2" color="black" fontWeight={700}>
                  ${grant.value.toFixed(2)}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
        {hover && (
          <CardOverlay>
            <Flex
              sx={{
                flexDirection: 'column-reverse',
                alignItems: 'center',
                height: 1,
                p: 2,
                backgroundColor: 'background.transparentCard',
                borderRadius: 2,
              }}
            >
              <ViewDetailButton onClick={() => handleClickOpen()}>
                View Details
              </ViewDetailButton>
              <MuiLink
                underline="none"
                rel="noreferer"
                target="_blank"
                href={getLink(grant)}
                sx={{ width: '75%', maxWidth: '250px' }}
              >
                <VisitButton sx={{ width: '100%', m: 0 }}>
                  Visit Source
                </VisitButton>
              </MuiLink>
              <Link
                sx={{ width: '100%', textAlign: 'center', mb: 1 }}
                underline="none"
                href={`/my-grants/new?grantId=${grant.id}`}
              >
                <UseButton>Use this grant template</UseButton>
              </Link>
            </Flex>
          </CardOverlay>
        )}
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{grant?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ mb: 4, overflow: 'auto' }}>
              {grant?.description && (
                <Text
                  variant="body2"
                  color="black"
                  display={'block'}
                  maxheight={'350px'}
                >
                  {grant?.description}
                </Text>
              )}
            </Box>
            <Grid xs={12} spacing={2} container>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Text variant="body2" color="black" display={'block'}>
                    Posted Date:
                  </Text>
                  <Text variant="body2" color="black" fontWeight={700}>
                    {moment(grant.createdDate).format('MMM DD, YYYY')}
                  </Text>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Text variant="body2" color="black" display={'block'}>
                    Estimated Total Program Funding:
                  </Text>
                  <Text variant="body2" color="black" fontWeight={700}>
                    ${grant.value.toFixed(2)}
                  </Text>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CardButton
            autoFocus
            onClick={handleClose}
            sx={{ color: 'background.button.dark' }}
          >
            Close
          </CardButton>
          <Box justifyContent="flex-end">
            <MuiLink
              variant="contained"
              underline="none"
              rel="noreferer"
              target="_blank"
              href={getLink(grant)}
            >
              <CardButton
                variant="contained"
                sx={{ backgroundColor: 'secondary.main' }}
              >
                Visit Source
              </CardButton>
            </MuiLink>
            <Link href={`/my-grants/new?grantId=${grant.id}`}>
              <CardButton
                variant="contained"
                sx={{ backgroundColor: 'background.button.dark', ml: 1 }}
              >
                Use this grant template
              </CardButton>
            </Link>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default TemplateCard;
