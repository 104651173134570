import React, { useContext, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const SnackbarContext = React.createContext();

const SNACKBAR_VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [snackbar, setSnackbar] = useState();

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const showSnackbar = ({ message, variant, vertical, horizontal }) => {
    setSnackbar({ message, variant, vertical, horizontal });
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: snackbar?.vertical ?? 'top',
          horizontal: snackbar?.horizontal ?? 'center',
        }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar?.variant || SNACKBAR_VARIANTS.INFO}
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbar = () => useContext(SnackbarContext);

export { SnackbarContext, SnackbarProvider, useSnackbar, SNACKBAR_VARIANTS };
