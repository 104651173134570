import { Link as RouterLink } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useState } from 'react';
import DeleteDialog from 'components/DeleteDialog';

const GridAction = ({
  objectName,
  editUrl,
  onDelete,
  hideEdit,
  hideDelete,
  children,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-evenly">
        {!hideEdit && (
          <IconButton title="Edit" component={RouterLink} to={editUrl}>
            <EditTwoToneIcon color="primary" />
          </IconButton>
        )}
        {!hideDelete && (
          <IconButton title="Delete" onClick={() => setOpen(true)}>
            <DeleteTwoToneIcon color="error" />
          </IconButton>
        )}
        {children}
      </Box>
      {open && (
        <DeleteDialog
          objectName={objectName}
          open={open}
          onClose={() => setOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default GridAction;
