import { Attachment } from '@mui/icons-material';
import { Box, styled, Button, CardMedia, TextField } from '@mui/material';
import { BRAND_COLORS } from 'theme';

const Input = styled('input')({
  display: 'none',
});

const StyledText = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: BRAND_COLORS.darkGray,
    opacity: 0.5,
  },
  '& .MuiInputBase-root': {
    backgroundColor: BRAND_COLORS.white,
  },
});

const UploadFile = ({
  fieldId,
  onChange,
  url = '',
  accept = 'image/*',
  multiple = false,
  useImagePreview = true,
  textFieldProps,
}) => {
  const handleChange = (e) => {
    if (!onChange) return;
    onChange(multiple ? e.target.files : e.target.files[0]);
  };
  return (
    <>
      <Box mb={1}>
        <StyledText
          InputProps={{ startAdornment: <Attachment /> }}
          mb={1}
          value={url}
          disabled
          fullWidth
          {...textFieldProps}
        />
      </Box>
      <Input
        accept={accept}
        id={fieldId}
        multiple={multiple}
        type="file"
        onChange={handleChange}
      />
      <Box component="label" htmlFor={fieldId}>
        {useImagePreview ? (
          <Box
            display="inline-block"
            height={300}
            width={300}
            sx={{ cursor: 'pointer' }}
          >
            <CardMedia
              component="img"
              image={url}
              style={{ borderRadius: 10 }}
            />
          </Box>
        ) : (
          <Button variant="contained" component="span" sx={{ mt: 1 }}>
            Upload
          </Button>
        )}
      </Box>
    </>
  );
};

export default UploadFile;
