import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  TextField,
} from '@mui/material';
import { resetPassword, validatePasswordResetTokenQuery } from 'api';
import { Title2, Body, Small } from 'components/Text';
import { SNACKBAR_VARIANTS, useSnackbar } from 'components/Snackbar';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showSnackbar } = useSnackbar();
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const token = useMemo(() => searchParams.get('token'), [searchParams]);
  const {
    data: validateTokenResult,
    isLoading: validateTokenBusy,
    error: validateTokenError,
  } = useQuery(validatePasswordResetTokenQuery(token));

  const { mutate, isLoading, error } = useMutation(resetPassword, {
    onSuccess: (data) => {
      if (!data) return;
      setPassword('');
      setConfirmedPassword('');
      showSnackbar({
        variant: SNACKBAR_VARIANTS.SUCCESS,
        message: 'Your password has been successfully updated.',
      });
      navigate('/auth/sign-in');
    },
  });

  useEffect(() => {
    if (validateTokenError) {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: 'Your password reset token is invalid',
      });
    }
    if (error) {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message:
          typeof error === 'string'
            ? error
            : error?.message ?? 'Something went wrong. Please try again.',
      });
    }
  }, [validateTokenError, error]);

  const setNewPassword = async () => {
    if (password !== confirmedPassword) {
      showSnackbar({
        variant: SNACKBAR_VARIANTS.ERROR,
        message: 'Password does not match',
      });
      return;
    }
    mutate({ token, newPassword: password });
  };

  if (validateTokenBusy) return null;
  const isTokenValid = validateTokenResult?.type === 'success';

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      position="relative"
    >
      <Box
        maxWidth={{ xs: 'initial', md: 550 }}
        minWidth={{ xs: 'initial', md: 550 }}
        width={{ xs: '90%', md: '100%' }}
        mx="auto"
        mt={{ xs: 4, md: 5.625 }}
        mb={{ xs: 6.25, md: 12.5 }}
      >
        <Card>
          <Box px={{ xs: 3.75, md: 7 }} my={5.625} width="100%">
            {!isTokenValid && (
              <>
                <Box mt={0} mb={2}>
                  <Title2 fontFamily="Playfair Display" mt={0} mb={5}>
                    New password
                  </Title2>
                </Box>
                <Box mb={3}>
                  <Body color="grey.800" my={0}>
                    Your password reset token is invalid or has expired. Click
                    the button below to resend your password generation link.
                  </Body>
                </Box>
                <Link href="/forgot-password" underline="none">
                  <Button
                    variant="contained"
                    large
                    sx={{ backgroundColor: 'background.button.dark' }}
                  >
                    Resend password reset link
                  </Button>
                </Link>
              </>
            )}
            {isTokenValid && (
              <>
                <Box mb={3}>
                  <Title2 mt={0} mb={2}>
                    New password
                  </Title2>
                </Box>
                <Body color="ui56" my={0}>
                  Create new password.
                </Body>
                <Box my={4}>
                  <TextField
                    value={password}
                    onChange={(e) => setPassword(e?.target?.value)}
                    placeholder="* * * * * *"
                    type="password"
                    label="Password"
                    disabled={isLoading || !isTokenValid}
                    fullWidth
                  />
                  <Small>Minimum 6 characters</Small>
                </Box>
                <Box my={4}>
                  <TextField
                    value={confirmedPassword}
                    onChange={(e) => setConfirmedPassword(e?.target?.value)}
                    placeholder="* * * * * *"
                    type="password"
                    label="Confirm new password"
                    disabled={isLoading || !isTokenValid}
                    fullWidth
                  />
                </Box>
                <Button
                  variant="contained"
                  large
                  onClick={setNewPassword}
                  disabled={isLoading || !isTokenValid}
                  sx={{ backgroundColor: 'background.button.dark' }}
                >
                  Reset Password
                  {isLoading && (
                    <Box
                      component={CircularProgress}
                      ml={2}
                      size={16}
                      color="inherit"
                    />
                  )}
                </Button>
              </>
            )}
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ResetPasswordPage;
