import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PasswordField = React.forwardRef(
  ({ onChange, value, type: _, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const handleChange = (e) => {
      if (onChange) onChange(e.target.value);
    };

    return (
      <TextField
        label="Password"
        placeholder="*******"
        name="password"
        type={isPasswordVisible ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        autoComplete="password"
        mb={2}
        ref={ref}
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setIsPasswordVisible((prev) => !prev)}>
                {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default PasswordField;
