import { IconButton, styled } from '@mui/material';
import { ReactComponent as Download } from 'images/icons/download.svg';
import { ReactComponent as Print } from 'images/icons/print.svg';
import { ReactComponent as Delete } from 'images/icons/delete.svg';
import Flex from '../Flex';

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.main,
  backgroundColor: 'transparent',
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: 16,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(2),
  '&:hover, &:focus, &:active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const Actions = ({
  onDownload,
  isDownloading,
  onPrint,
  isPrinting,
  onDelete,
  isDeleting,
}) => (
  <Flex center py={2}>
    <ActionButton disabled={isDownloading} onClick={onDownload}>
      <Download />
    </ActionButton>
    <ActionButton disabled={isPrinting} onClick={onPrint}>
      <Print />
    </ActionButton>
    <ActionButton disabled={isDeleting} onClick={onDelete}>
      <Delete />
    </ActionButton>
  </Flex>
);

export default Actions;
