import qs from 'qs';
import callApi from 'api/callApi';

const KEYS = {
  grants: (filter) => ['grants', filter],
  grant: (id) => [`grant_${id}`, id],
  downloadGrant: (id) => [`downloadGrant_${id}`, id],
};

export const getGrants = async (filters) => {
  const query = qs.stringify(filters);
  return callApi(`/grants?${query}`, {
    needsAuthToken: true,
  });
};

export const getGrantsById = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await callApi(`/grants/${id}`, {
    needsAuthToken: true,
  });
  return res;
};

export const getGrantsQuery = (filter) => ({
  queryKey: KEYS.grants(filter),
  queryFn: ({ queryKey }) => {
    const [, _] = queryKey;
    return getGrants(_);
  },
});

export const getGrantByIdQuery = (id) => ({
  queryKey: KEYS.grant(id),
  queryFn: getGrantsById,
  enabled: !!id,
});

export const createGrant = async (data) =>
  callApi(`/grants`, {
    method: 'POST',
    data,
    needsAuthToken: true,
  });

export const updateGrant = async (id, data) =>
  callApi(`/grants/${id}`, {
    method: 'PATCH',
    data,
    needsAuthToken: true,
  });

export const previewGrant = async (id, useFakeData = true) =>
  callApi(`/grants/${id}/preview?${qs.stringify({ useFakeData })}`, {
    method: 'GET',
    needsAuthToken: true,
    responseType: 'blob',
  });
