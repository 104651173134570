import Cookies from 'universal-cookie';
import { AUTH_TOKEN_KEY, APP_CONFIG } from 'utils/constants';

export const cookies = new Cookies();

export const getAuthTokenFromCookie = () => cookies.get(AUTH_TOKEN_KEY);

const getCookieOpts = () => {
  const opts = { path: '/' };
  if (window?.location?.host?.includes(APP_CONFIG.AUTH_DOMAIN)) {
    opts.domain = APP_CONFIG.AUTH_DOMAIN;
  }
  return opts;
};

export const setAuthCookie = (token) =>
  cookies.set(AUTH_TOKEN_KEY, token, getCookieOpts());

export const removeAuthTokenFromCookie = () =>
  cookies.remove(AUTH_TOKEN_KEY, getCookieOpts());
