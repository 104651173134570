export const GRANT_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
};

export const GRANT_CATEGORIES = {
  FEDERAL: 'federal',
  FORMULA: 'formula',
  COMPETITIVE: 'competitive',
};

export const GRANTS_FIELD_TYPES = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  DATE: 'date',
  FILE: 'file',
};

export const GRANTS_TYPES = {
  GRANT: 'grant',
  LETTER: 'letter',
  LINK: 'link',
};

export const COMMON_FIELDS = {
  firstName: '{{firstName}}',
  lastName: '{{lastName}}',
  email: '{{email}}',
  phoneNumber: '{{phoneNumber}}',
  company: '{{company}}',
};
