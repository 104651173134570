/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
import { uploadFile } from 'api';

async function dataUrlToFile(url, name) {
  const arr = url.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], name, { type: mime });
}

function getFileUrl(file) {
  const filename = Date.now();
  return uploadFile(file, 'grants', filename);
}

async function replacePagesLocalImages(payload) {
  for (const page of payload.pages ?? []) {
    let { content } = page;
    const localImages = content.match(/<img [^>]*src="data:[^"]*"[^>]*>/gm);
    if (!localImages?.length) continue;

    for (const local of localImages) {
      // parse "src"
      const [, localUrl] = local.match(/.*src="([^"]*)".*/);
      // data url to file
      const file = await dataUrlToFile(localUrl, Date.now().toString());
      // upload to api
      const uploadedUrl = await getFileUrl(file);
      // rewrite img tag with new url
      const newImgTag = local.replace(localUrl, uploadedUrl);
      // replace old tag with new img  tag
      content = content.replace(local, newImgTag);
    }
    page.content = content;
  }
}

export async function prepRequest(form) {
  const payload = form;
  if (payload.title) {
    payload.title = payload.title.trim();
  }
  if (payload.description) {
    payload.description = payload.description.trim();
  }
  if (payload.tags?.length) {
    payload.tags = payload.tags.filter((t) => Boolean(t?.trim()));
  }
  if (payload.url) {
    payload.url = payload.url.trim();
  }
  if (payload.fields?.length) {
    payload.fields = payload.fields.filter((t) => Boolean(t?.name?.trim()));
  }
  if (payload.attachments?.length) {
    payload.attachments = payload.attachments.filter((t) =>
      Boolean(t?.label?.trim())
    );
  }

  await replacePagesLocalImages(payload);

  return payload;
}

export function checkIfFieldsOnPages(data) {
  const { fields, pages } = data;
  return fields.every((field) => {
    const { name } = field;
    return pages.some((page) => page.content.includes(`{{${name}}}`));
  });
}
