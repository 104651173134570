import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './index.css';

let eventDispatcher;

const TagsPluginMixin = ({ defaultTags = [], customTagCallback }) => {
  const TagsPlugin = (editor) => {
    editor.ui.registry.addMenuButton('grantstags', {
      text: 'Tags',
      fetch: (callback) => {
        const updateContent = (value) => {
          const { startOffset, endOffset } = editor.selection.getRng();
          const currentNode = editor.selection.getNode();
          const { innerText } = currentNode;
          const replaceRange = (s, start, end, substitute) =>
            s.substring(0, start) + substitute + s.substring(end);
          const newText = replaceRange(
            innerText,
            startOffset,
            endOffset,
            `{{${value}}}`
          );
          currentNode.innerText = newText;
        };

        const items = [
          ...defaultTags.map((t) => ({
            type: 'menuitem',
            text: t,
            onAction: () => {
              updateContent(t);
              eventDispatcher.fire('grantstags:select', { data: t });
            },
          })),
          {
            type: 'menuitem',
            text: 'Custom',
            onAction: () => {
              if (customTagCallback) {
                customTagCallback((customTag) => {
                  updateContent(customTag);
                  eventDispatcher.fire('grantstags:select', {
                    data: customTag,
                  });
                });
                return;
              }

              // eslint-disable-next-line no-alert
              const customTag = prompt('Tag name', 'my custom tag');
              if (customTag) {
                updateContent(customTag);
                eventDispatcher.fire('grantstags:select', { data: customTag });
              }
            },
          },
        ];
        callback(items);
      },
    });
  };
  return TagsPlugin;
};

const DocumentEditor = ({
  initialValue,
  onChange,
  onTagEntered,
  onCustomTagSelected,
  defaultTags,
  height = 980,
}) => {
  const editorRef = useRef(null);

  const handleChange = () => {
    if (!onChange) return;
    onChange(editorRef.current.getContent());
  };

  return (
    <Editor
      tinymceScriptSrc={'vendors/tinymce/js/tinymce/tinymce.min.js'}
      onInit={(_, editor) => {
        editorRef.current = editor;
      }}
      onChange={handleChange}
      initialValue={initialValue}
      init={{
        promotion: false,
        setup: () => {
          window.tinymce.PluginManager.add(
            'grantstags',
            TagsPluginMixin({
              defaultTags,
              customTagCallback: onCustomTagSelected,
            })
          );
          eventDispatcher = new window.tinymce.util.EventDispatcher();
          eventDispatcher.on('grantstags:select', (data) => {
            if (onTagEntered) onTagEntered(data.data);
          });
        },

        plugins:
          'grantstags preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
        editimage_cors_hosts: ['picsum.photos'],
        menubar: 'file edit view insert format tools table help',
        toolbar:
          'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        quickbars_selection_toolbar:
          'grantstags template bold italic | quicklink h2 h3 blockquote quickimage quicktable',

        autosave_ask_before_unload: true,
        autosave_interval: '30s',
        autosave_prefix: '{path}{query}-{id}-',
        autosave_restore_when_empty: false,
        autosave_retention: '2m',
        image_advtab: true,
        link_list: [
          { title: 'My page 1', value: 'https://www.tiny.cloud' },
          { title: 'My page 2', value: 'http://www.moxiecode.com' },
        ],
        image_list: [
          { title: 'My page 1', value: 'https://www.tiny.cloud' },
          { title: 'My page 2', value: 'http://www.moxiecode.com' },
        ],
        image_class_list: [
          { title: 'None', value: '' },
          { title: 'Some class', value: 'class-name' },
        ],
        importcss_append: true,
        file_picker_callback: (callback, _, meta) => {
          /* Provide file and text for the link dialog */
          if (meta.filetype === 'file') {
            callback('https://www.google.com/logos/google.jpg', {
              text: 'My text',
            });
          }

          /* Provide image and alt text for the image dialog */
          if (meta.filetype === 'image') {
            callback('https://www.google.com/logos/google.jpg', {
              alt: 'My alt text',
            });
          }

          /* Provide alternative source and posted for the media dialog */
          if (meta.filetype === 'media') {
            callback('movie.mp4', {
              source2: 'alt.ogg',
              poster: 'https://www.google.com/logos/google.jpg',
            });
          }
        },
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        height,
        image_caption: true,

        noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image table',
        skin: 'oxide',
        content_css: 'default',
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
      }}
    />
  );
};

export default DocumentEditor;
