import { Card } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Flex from 'components/Flex';

const GrantDetailsSkeleton = () => (
  <Card sx={{ height: '100%', px: 2, py: 3, mt: 4, mb: 3 }}>
    <Stack spacing={1}>
      <Skeleton variant="rounded" width={400} height={30} sx={{ mb: 3 }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="90%" />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="70%" />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="80%" />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
      <Flex justifyContent="end">
        <Skeleton variant="rounded" width={100} height={40} sx={{ mt: 3 }} />
      </Flex>
    </Stack>
  </Card>
);

export default GrantDetailsSkeleton;
