import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField } from '@mui/material';
import GoogleMap from 'google-map-react';

const DEFAULT_ZOOM = 17;
const DEFAULT_COUNTRY = 'United States';
const DEFAULT_GEOLOCATION = {
  lat: 35.5950581,
  lng: -82.5514869,
};
const DEFAULT_LOCATION = {
  country: DEFAULT_COUNTRY,
  name: 'Asheville, North Carolina, USA',
  ...DEFAULT_GEOLOCATION,
};

const LocationFieldContext = React.createContext();
const useLocationField = () => {
  const context = React.useContext(LocationFieldContext);
  if (!context) {
    throw new Error('LocationFieldContext failed to initialize');
  }
  return context;
};

const LocationSearchField = ({ location, onMapSelect, boxProps, ...props }) => {
  const { map: mapValue } = useLocationField();
  const { api: mapApi, instance: map, apiLoaded } = mapValue;

  let searchBox;
  let marker;
  const inputRef = useRef();

  const handlePlacesChange = () => {
    const selected = searchBox.getPlaces();
    const place = selected[0];
    if (!place.geometry) return;
    const state = place.address_components?.find((s) =>
      s.types.includes('administrative_area_level_1')
    )?.long_name;
    const county = place.address_components?.find((s) =>
      s.types.includes('administrative_area_level_2')
    )?.long_name;
    const country =
      place.address_components?.find((s) => s.types.includes('country'))
        ?.long_name ?? DEFAULT_COUNTRY;
    marker = new mapApi.Marker({
      position: place.geometry.location,
      map,
    });
    if (onMapSelect) {
      const { geometry, place_id: placeId } = place;
      if (!geometry?.location) return;
      const { lat, lng } = geometry.location;
      onMapSelect({
        lat: lat(),
        lng: lng(),
        id: placeId,
        state,
        county,
        country,
        name: inputRef?.current?.value,
      });
    }
    map.setCenter(place.geometry.location);
    map.setZoom(DEFAULT_ZOOM);
    inputRef.current.blur();
  };

  const handleClearInput = () => {
    inputRef.current.value = '';
    if (marker) marker.setMap(null);
  };

  useEffect(() => {
    if (!mapApi) return undefined;

    searchBox = new mapApi.places.SearchBox(inputRef.current);
    searchBox.addListener('places_changed', handlePlacesChange);
    searchBox.bindTo('bounds', map);
    if (!location) return undefined;
    const { lat, lng } = location;
    const latLng = new mapApi.LatLng(lat, lng);
    marker = new mapApi.Marker({
      position: latLng,
      map,
    });
    return () => {
      mapApi.event.clearInstanceListeners(inputRef.current);
    };
  }, [mapApi]);

  return (
    apiLoaded && (
      <Box display="flex" my={2} {...boxProps}>
        <TextField
          inputRef={inputRef}
          type="text"
          defaultValue={location?.name ?? ''}
          onFocus={handleClearInput}
          fullWidth
          {...props}
        />
      </Box>
    )
  );
};

const LocationField = ({
  googleMapsKey,
  containerBoxProps,
  children,
  location,
}) => {
  const [map, setMap] = useState({
    apiLoaded: false,
    instance: null,
    api: null,
  });

  const handleApiLoaded = ({ map: _map, maps: _maps }) => {
    setMap((prev) => ({
      ...prev,
      apiLoaded: true,
      instance: _map,
      api: _maps,
    }));
  };

  return (
    <LocationFieldContext.Provider value={{ map }}>
      {children}
      <Box height={360} {...containerBoxProps}>
        <GoogleMap
          defaultZoom={DEFAULT_ZOOM}
          center={location ?? DEFAULT_GEOLOCATION}
          bootstrapURLKeys={{
            key: googleMapsKey ?? process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            libraries: ['places', 'geometry'],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
        />
      </Box>
    </LocationFieldContext.Provider>
  );
};

export { LocationSearchField, LocationField, DEFAULT_LOCATION };
