import { Divider as MuiDivider, styled } from '@mui/material';

const Divider = styled(({ children, ...props }) => (
  <MuiDivider {...props}>{children}</MuiDivider>
))(({ theme }) => ({
  background: theme.palette.black,
  margin: 0,
}));

export default Divider;
