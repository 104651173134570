import * as _ from 'lodash';

const EXPORT_SEPARTOR = ',';

const downloadBlob = (blob, fileName = 'grid-data.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportCSV = (gridRef) => {
  const columns = gridRef.current.visibleColumns.filter((c) => !c.noExport);
  const rows = [];
  for (const item of gridRef.current.data) {
    const rowLines = [];
    for (const col of columns) {
      const path = col.exportPath || col.name;
      const value = _.get(item, path);
      rowLines.push(`"${value}"`);
    }
    rows.push(rowLines.join(EXPORT_SEPARTOR));
  }

  const header = columns
    .map((c) => c.exportTitle || c.header)
    .join(EXPORT_SEPARTOR);
  const contents = [header].concat(rows).join('\n');
  const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
  downloadBlob(blob);
};
