import { Outlet } from 'react-router-dom';
import { Box, IconButton, Container } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import Flex from 'components/Flex';
import Error from 'components/Error';
import { useLayout } from 'contexts/Layout';
import { useAuth } from 'contexts/Auth';
import useValidateUserAccess from 'hooks/useValidateUserAccess';
import Drawer from '../Drawer';
import MainContent from '../MainContent';

const NoFaqLayout = () => {
  const { drawerOpen, toggleDrawer } = useLayout();
  const { user } = useAuth();
  const { isInitialized } = useValidateUserAccess();

  if (!isInitialized) return null;

  if (!user || !user.roles?.includes('curator')) {
    return <Error title="Forbidden Access" />;
  }

  return (
    <Flex height="100vh" position="relative">
      <Box position="absolute">
        <IconButton onClick={toggleDrawer}>
          <MenuTwoToneIcon />
        </IconButton>
      </Box>
      <Drawer open={drawerOpen} onToggleSidebar={toggleDrawer} />
      <MainContent drawerOpen={drawerOpen} faqOpen>
        <Flex height="100vh" width="100%">
          <Box
            component={Container}
            bgcolor="common.white"
            py={6}
            height="100%"
            overflow="hidden"
          >
            <Outlet />
          </Box>
        </Flex>
      </MainContent>
    </Flex>
  );
};

export default NoFaqLayout;
