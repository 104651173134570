import { styled } from '@mui/material';
import React from 'react';

const HiddenInput = styled(
  React.forwardRef((props, ref) => <input ref={ref} {...props} />)
)({
  display: 'none',
});

export default HiddenInput;
