import callApi from 'api/callApi';

const KEYS = {
  validatePasswordResetToken: (token) => ['validatePasswordResetToken', token],
};

export const getToken = async (email, password) =>
  callApi(`/auth/login`, {
    method: 'POST',
    data: { email, password },
  });

export const register = async (payload) =>
  callApi('/auth/register', {
    method: 'POST',
    useFullError: true,
    data: payload,
  });

export const getUserByToken = async () =>
  callApi(`/users/by-token`, {
    needsAuthToken: true,
  });

export const forgotPassword = async (payload) =>
  callApi(`/users/forgot-password`, {
    method: 'POST',
    data: payload,
  });

export const resetPassword = async (payload) =>
  callApi('/users/reset-password', {
    method: 'POST',
    data: payload,
  });

export const changePassword = (id, data) =>
  callApi(`/users/${id}/change-password`, {
    method: 'PATCH',
    data,
    needsAuthToken: true,
  });

export const verifyEmail = async (code) =>
  callApi('/users/verify-email', {
    method: 'POST',
    data: { code },
  });

const validatePasswordResetToken = async ({ queryKey }) => {
  const [, token] = queryKey;
  return callApi(`/users/validate-password-reset-token?token=${token}`);
};

export const validatePasswordResetTokenQuery = (token) => ({
  queryKey: KEYS.validatePasswordResetToken(token),
  queryFn: validatePasswordResetToken,
  enabled: !!token,
});
