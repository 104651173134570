import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

export function useDataGridQuery(key, handler, defaultConfig) {
  const [dataSource, setDataSource] = useState(
    Promise.resolve({ data: [], count: 0 })
  );
  const [config, setConfig] = useState(defaultConfig);
  const { data, isLoading, error } = useQuery(
    [key, config],
    ({ queryKey }) => {
      const [, param] = queryKey;
      return handler(param);
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    if (!data) return;
    setDataSource(Promise.resolve({ data: data.items, count: data.total }));
  }, [data]);

  return {
    dataSource,
    isLoading,
    error,
    updateConfig: (value) => setConfig(value),
  };
}
